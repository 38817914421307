import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { handleBlock } from '@zert-packages/actions/commonReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    listStyle: 'none',
    padding: 0,
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: 5,
    height: 30,
    alignItems: 'center',
    margin: '5px 0px'
  },
  itemMenu: {
    cursor: 'pointer',
    padding: '5px 10px',
    borderRight: '1px solid #919191',
    '&:hover': {
      backgroundColor: '#ffffff',
      color: '#05364A'
    }
  }
}));

function ImgEditorMenu() {
  const cl = useStyles();
  const { block } = useSelector((state) => state);
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleAction = (type) => {
    dispatch(handleBlock(type, 'imgEditorActionType'));
  };

  const dataMenu = [
    {
      name: intl.formatMessage({ id: 'ElementTile.ImgEditor.Header.Upload', defaultMessage: 'Upload' }),
      type: 'Upload'
    },
    {
      name: intl.formatMessage({ id: 'ElementTile.ImgEditor.Header.Download', defaultMessage: 'Download' }),
      type: 'Download'
    },
    {
      name: intl.formatMessage({ id: 'ElementTile.ImgEditor.Header.newPhrase', defaultMessage: 'Add new phrase' }),
      type: 'newPhrase'
    },
    {
      name: intl.formatMessage({ id: 'ElementTile.ImgEditor.Header.NewHotspot', defaultMessage: 'New hotspot' }),
      type: 'NewHotspot'
    },
    {
      name: intl.formatMessage({ id: 'ElementTile.ImgEditor.Header.NewForm', defaultMessage: 'New Form' }),
      type: 'NewForm'
    },
    {
      name: intl.formatMessage({ id: 'ElementTile.ImgEditor.Header.NewArrow', defaultMessage: 'New arrow' }),
      type: 'NewArrow'
    },
    {
      name: intl.formatMessage({ id: 'ElementTile.ImgEditor.Header.ZoomIn', defaultMessage: 'Zoom in' }),
      type: 'ZoomIn'
    },
    {
      name: intl.formatMessage({ id: 'ElementTile.ImgEditor.Header.ZoomOut', defaultMessage: 'Zoom out' }),
      type: 'ZoomOut'
    }
  ];

  return (
    <ul className={cl.root}>
      {dataMenu.map((el, i) => (
        <li key={i} className={cl.itemMenu} onClick={() => handleAction(el.type)}>
          {el.name}
        </li>
      ))}
      {block.selectedImgFigure && (
        <li className={cl.itemMenu} onClick={() => handleAction('Remove')}>
          <FormattedMessage id="ElementTile.ImgEditor.Remove" defaultMessage="Remove" />
        </li>
      )}
      {block.selectedImgFigure?.label && (
        <>
          <li className={cl.itemMenu} onClick={() => handleAction('left')}>
            <FormattedMessage id="ElementTile.ImgEditor.AlignLeft" defaultMessage="Align left" />
          </li>
          <li className={cl.itemMenu} onClick={() => handleAction('center')}>
            <FormattedMessage id="ElementTile.ImgEditor.AlignCenter" defaultMessage="Align center" />
          </li>
          <li className={cl.itemMenu} onClick={() => handleAction('right')}>
            <FormattedMessage id="ElementTile.ImgEditor.AlignRight" defaultMessage="Align right" />
          </li>
          <li className={cl.itemMenu} onClick={() => handleAction('Remove')}>
            <FormattedMessage id="ElementTile.ImgEditor.LockReference" defaultMessage="Lock reference" />
          </li>
          <li className={cl.itemMenu} onClick={() => handleAction('Remove')}>
            <FormattedMessage id="ElementTile.ImgEditor.UnlockReference" defaultMessage="Unlock reference" />
          </li>
        </>
      )}
    </ul>
  );
}

export default ImgEditorMenu;
