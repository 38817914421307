import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {FormattedMessage} from 'react-intl';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import DialogAddAlias from 'clm/src/plugins/PhrasePlugin/components/dialog/DialogHandleImg/DialogAddAlias';
import {createVersion, storeBlockApi} from './API';
import {PanelContext} from '../InfoElementWrapper/InfoElementWrapper';
import {getSideBarMenu} from '../helpers/getSideBarMenu';
import {createInProgressSnack} from '../../common/snackBar';
import {
    addAlias, addLabel,
    approveElement,
    cancelBlock,
    copyElementBlock,
    duplicateElementBlock, duplicateItemBlock, handleItemsSave, handleReference, handleUngroup,
    pastElementBlock,
    removeElement,
    saveBlock,
    saveImg,
    saveIndex,
    savePhrase
} from '../helpers/ToolBarAction';
import OpenNewWindows from './OpenNewWindows';
import AlertTextDialog from "./dialog/AlertTextDialog";
import {useRoveFocus} from "@zert-packages/utils";
import DialogAddLabel from "./dialog/DialogAddLabel";
import LockReferenceDialog from "./dialog/LockReferenceDialog";
import {handleActionChildBlockHeader} from "@zert-packages/actions/commonReducer";
import {handleHistory} from "@zert-packages/utils/useHistory";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        flexWrap: 'wrap'
    },
    button: {
        color: '#178FC7',
        fontWeight: 'bold',
        fontSize: '10px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        minHeight: 21,
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 2,
        '&:hover': {
            color: '#ffffff'
        }
    },
    wrapperSaveImg: {
        '& input': {
            display: 'none'
        },
        '& label': {
            marginBottom: 0
        }
    }
}));

let load = false

function ToolBarBlockEditor({
                                el,
                                style = {},
                                context,
                                block,
                                setBlock = () => {
                                },
                                parentItemCreate,
                                blockItems,
                                saveOnlyItem
                            }) {
    const cl = useStyles();
    const {myLanguage, activeLocales,actionHeader, actionChildBlockHeader, block: selectedHeaderItem, historyUndoRedo} = useSelector((state) => state);
    const {versionId} = useParams();
    const [hover, setHover] = useState(false);
    const {values, setValues} = useContext(context || PanelContext);
    const snackbar = useSnackbar();
    const closeAll = values.closeAllBlockItems;
    const idSaveElement = values.action?.preSaveElement?.elementInfo.currentVersionId;
    const idTreeItem = values?.treeItem?.elementInfo?.currentVersionId;
    const actionElement = el;
    const {copyKey, insert} = useRoveFocus()
    const dispatch = useDispatch();


    const handleIcon = async (e, el, values, novLoading, blockProps = block, ) => {
        // return

        const block = blockProps;
        if (e) {
            e.stopPropagation();
            e.preventDefault()
        }

        const item = values.treeItem?.elementInfo;
        const mylocale = myLanguage.value;

        if (saveOnlyItem) {
            const type = actionElement?.elementInfo?.mimeType.split('/').pop();
            return saveOnlyItem(el.type, type, actionElement.elementInfo, snackbar);
        }



        if (!item || values.blockClickCreateItemsBlock || values.loadingSave || (!block && !values.treeItem?.action) || load) {
            return ;
        }

        setValues((pre) => ({...pre, loadingSave: true}));
        const type = item.mimeType.split('/').pop();


        console.log('el.type', el.type)

        load = true
        if(historyUndoRedo.session && el.type !== 'Preview' && el.type !== 'Cancel' &&  !values.treeItem?.action){
            console.log('handleHistory',)
            await handleHistory(historyUndoRedo, el.type, dispatch)
        }
        if (el.type === 'New version') {
            renderInExceptionRoot(AlertTextDialog,
                {
                    width: 'fit-content',
                    "setConfirm": () => {},
                    "text": "The selected element is not latest version and a new branch will therefor be " +
                        "created. Do you want to create a new branch?",
                    "title": "New branch?"
                });
            load =false
            return

            const generatingSnackbar = createInProgressSnack(
                snackbar,
                <FormattedMessage
                    id="ToolBarPhraseEditor.SnackBar.CreatingNewVersion"
                    defaultMessage="Creating a new version..."
                />
            );
            const res = await createVersion(item.companyId, item.versionId);
            if (res) {
                handleItemsSave(res, item, parentItemCreate, setValues)
            }
            snackbar.closeSnackbar(generatingSnackbar);
        }

        if (el.type === 'Approve') {
            const generatingSnackbar = createInProgressSnack(
                snackbar,
                <FormattedMessage id="ToolBarPhraseEditor.SnackBar.Approving" defaultMessage="Approving..."/>
            );
            await approveElement(generatingSnackbar, snackbar, item, false, setValues, parentItemCreate);

        }

        if (el.type === 'Copy') {
            await copyElementBlock(values);
        }

        if (el.type === 'Preview') {

        }

        if (el.type === 'Lock reference') {
            renderInExceptionRoot( LockReferenceDialog, {
                values,
                addReference: (val) => handleReference(val,'itemComment', parentItemCreate, values, mylocale, snackbar, setValues)
            })
        }

        if(el.type === 'UnlockReference'){
             await  handleReference(null,'itemComment', parentItemCreate, values, mylocale, snackbar, setValues)
        }



        if (el.type === 'Cut') {
            const cut = async () => {
                const generatingSnackbar = createInProgressSnack(
                    snackbar,
                    <FormattedMessage id="ToolBarPhraseEditor.SnackBar.Removing" defaultMessage="Removing..."/>
                );
                await copyElementBlock(values);
                await removeElement(generatingSnackbar, parentItemCreate, values, setValues, snackbar, false);
            }
            renderInExceptionRoot(AlertTextDialog,
                {
                    "setConfirm": cut,
                    "text": "Are you sure you want to cut this element?",
                    "title": "Cut ?"
                });
        }
        if (el.type === 'Save' && !item.approvedAt) {
            const generatingSnackbar =
                !novLoading &&
                createInProgressSnack(
                    snackbar,
                    <FormattedMessage id="ToolBarPhraseEditor.SnackBar.Saving" defaultMessage="Saving..."/>
                );
            // try {
            if (type === 'zert-phrase') {
                await savePhrase(values, activeLocales, mylocale, block, parentItemCreate, setValues, setBlock, versionId,false, snackbar);
            }
            if (type === 'zert-block' || type === 'zert-image') {

                await saveBlock(
                    values,
                    activeLocales,
                    mylocale,
                    block,
                    parentItemCreate,
                    setValues,
                    setBlock,
                    type === 'zert-image'
                );
            }
            if (type === 'zert-table') {
                setBlock((pre) => ({...pre, table: 'save'}));
                // await saveTable(values, block, mylocale, parentItemCreate, setValues, setBlock);
            }
            if (type === 'zert-index') {
                await saveIndex(values, activeLocales, mylocale, block, parentItemCreate, setValues, setBlock, versionId);
            }
            !novLoading && snackbar.closeSnackbar(generatingSnackbar);
        }

        if (el.type === 'Cancel') {
            setBlock((pre) => ({...pre, createType: null, expandedToolbar: false, beforeTreeItem: []}));
            cancelBlock(values, setValues, block?.blockItem);
        }


        if (el.type === 'Remove') {
            const generatingSnackbar = createInProgressSnack(
                snackbar,
                <FormattedMessage id="ToolBarPhraseEditor.SnackBar.Removing" defaultMessage="Removing..."/>
            );
            await removeElement(generatingSnackbar, parentItemCreate, values, setValues, snackbar, setBlock);
        }


        if (el.type === 'Ungroup') {
            handleUngroup(snackbar, values, activeLocales, mylocale, block, parentItemCreate, setValues, setBlock, type)
        }

        if (el.type === 'Add label') {
            renderInExceptionRoot(DialogAddLabel, {values,
                addLabel: (val) => addLabel(val, parentItemCreate, values, mylocale,  snackbar, setValues)
            })
            // renderInExceptionRoot(DialogAddAlias, {addAlias, values, versionId: item.versionId});
        }

        if (el.type === 'Paste') {
            await pastElementBlock(snackbar, values, activeLocales, mylocale, block, parentItemCreate, setValues, setBlock, type);
        }

        if (el.type === 'Duplicate') {
            await duplicateItemBlock(snackbar,
                values,
                type,
                mylocale,
                block,
                parentItemCreate,
                setValues,
                setBlock,)
        }
        load = false
        setValues((pre) => ({...pre, loadingSave: false, changeBlock: false, preSaveElement: null,
            action: {...pre.action, preSaveElement: null},
        }));
    };



    const saveItems = async () => {
        const item = {
            ...values,
            treeItem: el
        };
        await handleIcon(false, {type: 'Save'}, item);
        const res = {};
        if (closeAll) {
            res.treeItem = null;
        }
        if (values.beforeTreeItem && values.beforeTreeItem[1]) {
            res.beforeTreeItem = [values.beforeTreeItem[1]];
        }
        setValues((pre) => ({...pre, beforeTreeItem: res.beforeTreeItem}));
    };

    useEffect(() => {
        if (
            idSaveElement && idTreeItem && el &&
            idSaveElement !== idTreeItem &&
            el?.elementInfo?.currentVersionId === idSaveElement
        ) {
            setValues((pre) => ({
                ...pre,
                action: {...pre.action, preSaveElement: null}
            }));
            const item = {
                ...values,
                treeItem: el
            };
            handleIcon(false, {type: 'Save'}, item);
        }
    }, [idSaveElement, idTreeItem, values.treeItem, el]);

    useEffect(() => {
        if (values.beforeTreeItem?.length > 1 && values.changeBlock) {
            if (
                values.beforeTreeItem &&
                el?.identifier &&
                values.beforeTreeItem[0] &&
                el.identifier === values.beforeTreeItem[0]?.identifier
            ) {
                saveItems();
            }
        }
    }, [values.beforeTreeItem]);

    useEffect(() => {
        if (block?.saveCreateElement && values.treeItem.elementInfo.versionId === el.elementInfo.versionId && values.changeBlock) {
            setBlock((pre) => ({...pre, saveCreateElement: false}));
            const item = {
                ...values,
                treeItem: el
            };
            handleIcon(false, {type: 'Save'}, item);
        }
    }, [block?.saveCreateElement]);

    useEffect(() => {
        if (values.treeItem && values.treeItem.elementInfo && values.treeItem.elementInfo && (
            el?.elementInfo?.versionId === values.treeItem?.elementInfo?.versionId
            || el?.info?.versionId === values.treeItem?.elementInfo?.versionId
        )) {
            if(insert?.insert && parentItemCreate){
                handleIcon(false, {type: 'Paste'}, values);
            }
            if (copyKey?.copyKey) {
                 handleIcon(false, {type: 'Copy'}, values);
            }
        }
    }, [copyKey, insert]);


    useEffect(() => {
        if (closeAll && values.treeItem?.identifier === el?.identifier && values.treeItem) {
            console.log('closeAll', closeAll)
            saveItems();
            setValues((pre) => ({...pre,
                closeAllBlockItems: false,
                treeItem: {...values.parentBlock, parentTreeItem: true,
                    elementInfo: {...values.parentBlock?.info}}
            }));
        }
    }, [closeAll]);

    useEffect(() => {
        if(actionChildBlockHeader && selectedHeaderItem.selectedItem){
            if(
                selectedHeaderItem?.selectedItem?.elementInfo?.versionId === el?.elementInfo?.versionId
                || selectedHeaderItem?.selectedItem?.blockParent?.elementInfo?.versionId === el?.elementInfo?.versionId
            ){
                handleIcon(false, {type: actionChildBlockHeader}, values);
                dispatch(handleActionChildBlockHeader(null))
            }
        }
    }, [actionChildBlockHeader, selectedHeaderItem.selectedItem]);

    useEffect(() => {
        if(actionHeader?.type === 'Remove' && values.treeItem && values.treeItem.identifier !== '0'){
           if(el?.elementInfo && el?.elementInfo.versionId === values.treeItem?.elementInfo.versionId){
               const item = {
                   ...values,
                   treeItem: el
               };
               handleIcon(false, {type: actionHeader?.type}, item);
           }
        }
    }, [actionHeader])



    return (
        <div className={cl.root} style={style}>
            {el && getSideBarMenu(el).map((el, i) => (
                <div
                    onMouseEnter={() => {
                        setHover(i);
                    }}
                    onMouseLeave={() => {
                        setHover(false);
                    }}
                    style={{backgroundColor: i === hover ? el.color : ''}}

                    key={i}
                >
                    {/*{el.type === 'img' ? (*/}
                    {/*    <div className={cl.wrapperSaveImg}>*/}
                    {/*        <label>*/}
                    {/*            {el.name}*/}
                    {/*            <input accept=".svg, .png, .bmp, .gif, .jpg, .jpeg" type="file" id="file1"*/}
                    {/*                   onChange={saveImg}/>*/}
                    {/*        </label>*/}
                    {/*    </div>*/}
                    {/*) : (*/}
                        <div
                            className={cl.button}
                            onClick={(e) => handleIcon(e, el, values)}
                            style={{
                                opacity: !values.treeItem ? '0.5' : '',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            {el.child && <MenuIcon style={{height: 19}}/>}
                            {el.name}
                        </div>
                     {/*)}*/}
                </div>
            ))}
            {blockItems && <OpenNewWindows style={{marginLeft: 'auto'}} el={el}/>}
        </div>
    );
}

export default ToolBarBlockEditor;
