import _ from "lodash";
import {
    FETCH_RA_BEGIN,
    FETCH_RA_SUCCESS,
    FETCH_WF_RA_SUCCESS,
    FETCH_MEASURE_TEMPLATE_RA_SUCCESS,
    FETCH_RISKROW_TEMPLATE_RA_SUCCESS,
    FETCH_RA_OVERVIEW_SUCCESS,
    FETCH_RA_OVERVIEW_BEGIN,
    FETCH_RA_OVERVIEW_DONE,
    FETCH_RA_FAILURE,
    FETCH_RA_LOADED,
    LOAD_CATALOG_BYID_SUCCESS,
    WIDTH,
    HEIGHT,
    FETCH_ADD_MEASURE,
    FETCH_ADD_MEASURE_DONE,
    FETCH_ADD_MEASURE_FINISH,
    LOAD_QUESTINAIRE_TEMPLATES,
    START_LOAD_QUESTINAIRE_TEMPLATES,
    ELEMENT_CREATED,
    ELEMENT_CREATE_START,
    ELEMENT_CREATED_AND_LOADED,
    LOAD_QUESTIONAIRE_TEMPLATE,
    CHECKLIST_SUCCESS,
    CHECKLIST_LOADED,
    CHECKLIST_START_LOADING,
    STORE_PARTICIPANT,
    CHECKLIST_UPLOAD_BASIS_BEGIN,
    CHECKLIST_UPLOAD_BASIS,
    CHECKLIST_UPLOAD_BASIS_SUCCESS,
    RA_UPLOAD_BASIS_BEGIN,
    RA_UPLOAD_BASIS,
    RA_UPLOAD_BASIS_SUCCESS,
    RA_UPLOAD_BASIS_CLEAR,
    ADD_RISKROW_BEGIN,
    ADD_RISKROW,
    ADD_RISKROW_SUCCESS,
    STORE_START,
    STORE_SUCCESS,
    STORE_ERROR,
    CATALOG_COUNTER_INFOS_START,
    CATALOG_COUNTER_INFOS_DONE,
    CATALOG_COUNTER_INFOS_SUCCESS,
    PERFORM_MEASURE_START,
    PERFORM_MEASURE_DONE,
    PERFORM_MEASURE_ERROR,
    CLEAN_MEASURE_ERROR,
    FETCH_QUESTINAIRE_TEMPLATE_BEGIN,
    FETCH_QUESTINAIRE_TEMPLATE_SUCCESS,
    FETCH_WF_SI_SUCCESS,
    SafetyInspection_START_LOADING,
    SafetyInspection_LOADED,
    SafetyInspection_SUCCESS,
    PERFORM_SafetyInspection_DONE,
    ELEMENT_APROVE_START,
    ELEMENT_APROVED,
    ADD_RISKROW_POS,
    RA_ADD_NODE,
    LOAD_QUESTIONAIRE_TEMPLATE_UPDATE_DONE,
    RA_ADD_NODE_DONE,
    MY_TASKS_ACTION,
    FETCH_DEVIATION_TEMPLATES_SUCCESS,
    FETCH_DEVIATION_TEMPLATE_BEGIN,
    FETCH_DEVIATION_TEMPLATE_SUCCESS,
    FETCH_DEVIATION_TEMPLATES_BEGIN,
    FETCH_DEVIATION_TEMPLATE_WORKFLOW_SUCCESS,

    // FETCH_DEVIATION_TEMPLATE_WORKLFOW_SUCCESS
    LOAD_CATALOG_PLACEMENT_SUCCESS,
    LOAD_CATALOG_PLACEMENT_LOADED,
    LOAD_CATALOG_PLACEMENT_BEGIN,
    LOAD_CATALOG_SUCCESS,
    LOAD_CATALOG_LOADED,
    LOAD_CATALOG_BEGIN,
    LOAD_CATALOG_SUCCESS2,
    LOAD_CATALOG_LOADED2,
    LOAD_CATALOG_BEGIN2,
    LOAD_QUESTIONAIRE_TEMPLATE_UPDATE_START,
    FETCH_ACTION_PLAN_SUCCESS,
    FETCH_ACTION_PLAN_BEGIN,
    FETCH_ACTION_PLAN_MYJOB_SUCCESS,
    FETCH_ACTION_PLAN_WORKLFOW_SUCCES,
    FETCH_ACTION_PLAN_WORKLFOW_BEGIN,
    ACTION_PLAN_TASK_COUNT,
    FETCH_TASK_PERFROM_SUCCESS,
    FETCH_TASK_PERFROM_BEGIN,
    FETCH_TASK_PERFROM_DONE,
    FETCH_DEVIATION_SUCCESS,
    FETCH_DEVIATION_BEGIN,
    FETCH_DEVIATION_PERFORMED,
    FETCH_DEVIATION_PERFORM,
    FETCH_MEASURE_BEGIN,
    FETCH_MEASURE_SUCCESS,
    FETCH_MEASURE_DONE,
    LOAD_CATALOG_NODE_START,
    LOAD_CATALOG_DATA_SUCCESS,
    FETCH_RISKROW_TEMPLATES_SUCCESS,
    FETCH_RISKROW_TEMPLATES_START,
    RA_RESCENT_SUCCESS,
    RA_RESCENT_START,
    START_HELP_FETCH,
    START_HELP_DONE,
    ADD_RISKROW_NODE,
    FOCUS_RISKROW,
    RA_COVER_UPLOAD_BEGIN,
    RA_COVER_UPLOAD_BASIS,
    RA_COVER_UPLOAD_SUCCESS,
    REPORT_DONE,
    ERROR_REPORT,
    REPORT_START,
    REPORT_STATUS,
    LOAD_CATALOG_COUNTERS_SUCCESS,
    LOAD_CATALOG_COUNTERS_START,

    MY_LOCALE,
    MY_USER_FETCH,
    MY_LOCALE_FETCH,
    FETCH_MY_NEWS,
    FETCH_NEWS,
    FETCH_NEWS_START,
    FETCH_COPY_ELEMENTS,
    FETCH_CUT_ELEMENTS,
    FETCH_PASTE_ELEMENTS,
    QUESTION_FILE_REMOVE,
    QUESTION_FILE_REMOVE_START,
    QUESTION_FILE_REMOVE_DONE,
    PERFORM_MEASURE_FINISHED,
    WANT_TO_SEE_INTRO,
    RA_ACTIONS_DISABLED,
    RA_ACTIONS_ENABLED,
    TASKS_LOADER_ACTION_PLAN_ON,
    TASKS_LOADER_ACTION_PLAN_OFF,
    UPDATE_EMPTY_RISK_ROW,
    SET_OFLINE,
    SET_ONLINE,
    ADD_NEW_NOTIFICATION,
    ACTION_PLAN_PAGE,
    ACTION_PLAN_TASK_PLANNED_COUNT,
    FETCH_MY_NOTIFICATIONS,
    FETCH_NOTIFICATION,
    LOAD_CATALOGS_SUCCESS,
    RESTORE_STATE,
    LOAD_CATALOGS_BEGIN,
    LOAD_CATALOGS_LOADED,
    SET_HEADER_SCROLL,
    MY_LANGUAGE,
    SET_ACTION_HEADER,
    CREATE_NEW_ELEMENT_PATEL,
    SET_BLOCK,
    SET_ACTION_CHILD_BLOCK_HEADER, SET_HISTORY_UNDO_REDO,
} from "./actions";

import {riskstructureReducer} from "./riskstructureActions";
import {coreReducer} from "./coreReducers";
import {explorerReducer} from "./explorerActions";
import {searchReducer} from "./searchActions";

import {ceSearchReducer} from "./ceimportActions";
import {PREPARE_STATE_FOR_NEW_CHECKLIST} from "./checklistActions";
import {shortcutReducer} from "./shortcutReducer";
import SideBar from "@zert-packages/components/side-bar";
import PhraseEditor from "clm/src/plugins/PhrasePlugin/components/PhraseEditor";

export const initialState = {
    riskassesment: null,
    riskrow: null,
    newversionid: null,
    template: null,
    measure: null,
    searchRequest: new Map(),
    workflowtemplate: null,
    siworkflowtemplate: null,
    measuretemplate: null,
    mainIdentifier: null,
    loading: true,
    loadingTemplates: false,
    questionairetemplates: null,
    companieslist: [],
    error: null,
    witdh: 0,
    height: 0,
    catalogList: null,
    creatingNewElement: false,
    newElementCreated: false,
    questionairetemplate: null,
    checklist: null,
    question_basis: null,
    uploading_question_basis: false,
    adding_risk_row: false,
    store_element: false,
    measure_performing: false,
    updated_measure: null,
    perform_measure_error: null,
    selectedCompanyId: null,
    ra_basis: null,
    uploading_ra_basis: false,
    safetyinspection: null,
    approving: false,
    deviationtemplate: null,
    dworkflowtemplate: null,
    tasks: [],
    rescentElements: null,
    copiedElements: [],
    isCuted: false,
    catalogPlacement: null,
    actionplanworkflowtemplate: null,
    deviationworkflowtemplate: null,
    loading_worklflow_template: false,
    reload_action_plan: false,
    userslist: [],
    deviation: null,
    task_count: 0,
    catalog: null,
    cataloginfos: null,
    rrtemplates: null,
    loadingCatalog: {},
    loadingCatalogs: {
        0: false,
        1: false,
        2: false,
        3: false
    },
    rescentRiskAnalises: null,
    helpText: [],
    helpId: null,
    posid: null,
    nodeuuid: null,
    focusedId: -1,
    selectedQuestionaireTemplate: null,
    uploading_ra_cover: false,
    cover: null,
    catalogpermits: [],
    report_error: null,
    report_generation_started: false,
    report: null,
    report_callback: null,
    report_done: false,
    countersData: null,
    countersNodeuuid: null,
    loadingCounters: false,
    mylocale: null,
    myLanguage: null,
    myuser: null,
    mynews: null,
    news: null,
    riskrowsTemplates: [],
    workflowTemplates: [],
    selectedCatalog: {},
    taskSettings: {},
    newsVersionIds: -1,
    node_click_uuid: null,
    sibling: null,
    ra_node: null,
    permits: {},
    isOfline: false,
    matrixes: [],
    wantToSeeIntro: null,
    RAActionsDisabled: false,
    tasksLoadingActionPlan: true,
    actionPlanPage: {
        activeTab: 0,
        activeMimeType: 0,
        activeMimeTypeText: "*",
        activeUserType: 0,
        activeUser: null,
        order: {sorting: "changedAt", isAscending: -1}
    },
    scrollHeader: false,
    actionHeader: null,
    actionChildBlockHeader: null,
    sideBarOpen: true,
    createNewElementPatel: null,
    block: {
        selectedItem: null,
        phraseEditorActionType: '',
        editorState: null,
        imgEditorActionType: '',
        selectedImgFigure: null,
    },
    historyUndoRedo: {
        session: null,
        undoList: [],
        redoList: [],
        stepIndex: 0,
        action: null,
    }
};

export function commonReducer(state = initialState, action) {
    state = riskstructureReducer(state, action);
    state = coreReducer(state, action);
    state = explorerReducer(state, action);
    state = searchReducer(state, action);
    // state = projectReducer(state, action);
    state = ceSearchReducer(state, action);
    state = shortcutReducer(state, action);

    switch (action.type) {
        case SET_ACTION_HEADER:
            return {
                ...state,
                sideBarOpen: action.payload
            };
        case SET_HEADER_SCROLL:
            return {
                ...state,
                scrollHeader: action.payload
            };
        case CREATE_NEW_ELEMENT_PATEL:
            return {
                ...state,
                createNewElementPatel: action.payload
            };
        case RESTORE_STATE:
            return {
                ...state,
                ...action.payload
            };
        case ACTION_PLAN_PAGE:
            return {
                ...state,
                actionPlanPage: action.payload
            };

        case SET_OFLINE:
            return {
                ...state,
                isOfline: true,
                creatingNewElement: false
            };
        case SET_ONLINE:
            return {
                ...state,
                isOfline: false
            };
        case RA_ADD_NODE_DONE:
            return {
                ...state,
                node_click_uuid: null,
                sibling: null,
                ra_node: null
            };

        case FETCH_COPY_ELEMENTS:
            return {
                ...state,
                copiedElements: action.payload.copiedElements,
                isCuted: false
            };
        case FETCH_CUT_ELEMENTS:
            return {
                ...state,
                copiedElements: action.payload.copiedElements,
                isCuted: true
            };
        case FETCH_PASTE_ELEMENTS:
            return {
                ...state,
                isCuted: false
            };
        case FETCH_NEWS:
            return {
                ...state,
                news: action.payload.news
            };
        case FETCH_NEWS_START:
            return {
                ...state,
                newsVersionIds: action.payload.versionId
            };
        case FETCH_MY_NEWS:
            return {
                ...state,
                mynews: action.payload.mynews
            };
        case MY_LOCALE_FETCH:
            return {
                ...state
            };
        case MY_USER_FETCH:
            return {
                ...state,
                myuser: action.payload.user
            };
        case MY_LANGUAGE:
            return {
                ...state,
                myLanguage: action.payload,
            };
        case SET_ACTION_CHILD_BLOCK_HEADER:
            return {
                ...state,
                actionChildBlockHeader: action.payload,
            };
        case MY_LOCALE:
            return {
                ...state,
                mylocale: action.payload.locale
            };
        case LOAD_CATALOG_COUNTERS_SUCCESS:
            return {
                ...state,
                countersData: action.payload.countersData,
                loadingCounters: false
            };
        case LOAD_CATALOG_COUNTERS_START:
            return {
                ...state,
                countersNodeuuid: action.payload.nodeuuid,
                loadingCounters: true
            };

        case REPORT_DONE:
            return {
                ...state,
                report: action.payload.report,
                report_done: true,
                report_callback: null
            };
        case ERROR_REPORT:
            return {
                ...state,
                report: null,

                report_done: false
            };
        case REPORT_START:
            return {
                ...state,
                report_error: null,
                report: action.payload.report,
                report_callback: null,
                report_generation_started: true,
                report_done: false
            };
        case REPORT_STATUS:
            return {
                ...state,
                report_callback: action.payload.callback,
                report_done: false
            };

        case RA_COVER_UPLOAD_BEGIN:
            return {
                ...state,
                uploading_ra_cover: true,
                error: null,
                cover: null
            };
        case RA_COVER_UPLOAD_BASIS:
            return {
                ...state,
                cover: action.payload.basis
            };
        case RA_COVER_UPLOAD_SUCCESS:
            return {
                ...state,
                uploading_ra_cover: false
            };
        case RA_ADD_NODE:
            return {
                ...state,
                node_click_uuid: action.payload.uuid,
                sibling: action.payload.sibling,
                ra_node: action.payload.ra_node
            };
        case FOCUS_RISKROW:
            return {
                ...state,
                focusedId: action.payload.riskrow.status.id
            };
        case LOAD_CATALOG_BYID_SUCCESS:
            return {
                ...state,
                selectedCatalog: action.payload.catalog
            };

        case START_HELP_DONE:
            state.helpText[action.payload.helpId] = action.payload.helpText;

            return {
                ...state
            };

        case ADD_RISKROW_NODE:
            return {
                ...state,
                nodeuuid: action.payload.nodeuuid
            };

        case START_HELP_FETCH:
            return {
                ...state,
                helpId: action.payload.helpId
            };

        case FETCH_RISKROW_TEMPLATES_SUCCESS:
            return {
                ...state,
                rrtemplates: action.payload.templates
            };

        case RA_RESCENT_SUCCESS:
            return {
                ...state,

                loading: false,

                rescentRiskAnalises: action.payload.riskanalises
            };

        case RA_RESCENT_START:
            return {
                ...state,
                checklist: null,
                raoverview: null,
                loading: true,
                rescentRiskAnalises: null
            };
        case FETCH_RISKROW_TEMPLATES_START:
            return {
                ...state,
                loadingTemplates: true,

                rrtemplates: null
            };
        case LOAD_CATALOG_NODE_START:
            return {
                ...state,
                loadingCatalog: true,
                cataloginfos: null,
                error: null
            };
        case LOAD_CATALOG_DATA_SUCCESS:
            return {
                ...state,
                loadingCatalog: false,
                cataloginfos: action.payload.cataloginfos,

                error: null
            };
        case FETCH_MEASURE_DONE:
            return {
                ...state,
                loading: false,

                error: null
            };
        case FETCH_MEASURE_BEGIN:
            return {
                ...state,
                loading: true,
                measure_riskrow: null,
                error: null
            };
        case FETCH_MEASURE_SUCCESS:
            return {
                ...state,
                loading: true,
                measure_riskrow: action.payload.riskrow
            };

        case FETCH_DEVIATION_TEMPLATE_WORKFLOW_SUCCESS:
            return {
                ...state,
                deviationworkflowtemplate: action.payload.workflowtemplate
            };
        case FETCH_DEVIATION_PERFORMED:
            return {
                ...state,
                deviation: action.payload.deviation,
                measure_performing: false
            };
        case FETCH_DEVIATION_PERFORM:
            return {
                ...state,
                loading: false,
                measure_performing: true
            };
        case ACTION_PLAN_TASK_PLANNED_COUNT:
            return {
                ...state,
                task_planned_count: action.payload.count
            };
        case ACTION_PLAN_TASK_COUNT:
            return {
                ...state,
                task_count: action.payload.count
            };
        case FETCH_DEVIATION_BEGIN:
            return {
                ...state,
                loading: true,
                deviation: null,
                measure_performing: false
            };
        case FETCH_DEVIATION_SUCCESS:
            return {
                ...state,
                deviation: action.payload.deviation,
                loading: false
            };
        case FETCH_TASK_PERFROM_SUCCESS:
            return {
                ...state,
                reload_action_plan: true,
                measure_performing: false
            };
        case FETCH_TASK_PERFROM_BEGIN:
            return {
                ...state,
                reload_action_plan: false,
                measure_performing: true
            };
        case FETCH_TASK_PERFROM_DONE:
            return {
                ...state,
                measure_performing: false,
                reload_action_plan: false
            };

        case FETCH_ACTION_PLAN_WORKLFOW_SUCCES:
            return {
                ...state,
                loading_worklflow_template: false,
                error: null,
                actionplanworkflowtemplate: action.payload.workflowtemplate
            };
        case FETCH_ACTION_PLAN_WORKLFOW_BEGIN:
            return {
                ...state,
                loading_worklflow_template: true,
                error: null
            };

        case FETCH_ACTION_PLAN_MYJOB_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                reload_action_plan: false,
                rescentElements: action.payload.elements
            };
        case FETCH_ACTION_PLAN_SUCCESS:
            return {
                ...state,
                error: null,
                reload_action_plan: false,
                tasks: action.payload.tasks
            };
        case FETCH_ACTION_PLAN_BEGIN:
            return {
                ...state,
                error: null,
                reload_action_plan: false,
                loading: true
            };

        case FETCH_QUESTINAIRE_TEMPLATE_SUCCESS:
            return {
                ...state,
                loadingTemplates: false
            };
        case FETCH_DEVIATION_TEMPLATES_SUCCESS:
            return {
                ...state,
                error: null,
                loadingTemplates: true,
                deviationtemplates: action.payload.templates
            };
        case FETCH_DEVIATION_TEMPLATE_BEGIN:
            return {
                ...state,
                error: null,
                loadingTemplates: true
            };
        case FETCH_DEVIATION_TEMPLATE_SUCCESS:
            return {
                ...state,
                error: null,
                loadingTemplates: false,
                deviationtemplate: action.payload.template
            };
        case FETCH_DEVIATION_TEMPLATES_BEGIN:
            return {
                ...state,
                error: null,
                loadingTemplates: true
            };

        case PERFORM_SafetyInspection_DONE:
            return {
                ...state,
                error: null,
                loading: false,
                measure_performing: false,
                safetyinspection: action.payload.updated_safetyinspection
            };
        case SafetyInspection_START_LOADING:
            return {
                ...state,
                error: null,
                loadingsafetyinspection: true
            };
        case SafetyInspection_LOADED:
            return {
                ...state,
                error: null,
                loadingsafetyinspection: false
            };
        case SafetyInspection_SUCCESS:
            return {
                ...state,
                error: null,

                safetyinspection: action.payload.safetyinspection
            };

        case FETCH_WF_SI_SUCCESS:
            return {
                ...state,
                siworkflowtemplate: action.payload.workflowtemplate
            };

        case FETCH_QUESTINAIRE_TEMPLATE_BEGIN:
            return {
                ...state,
                siworkflowtemplate: null,
                loadingTemplates: true
            };
        case ELEMENT_APROVE_START:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.

            return {
                ...state,
                store_element: true,
                approving: true,
                error: null
            };

        case ELEMENT_APROVED:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.

            return {
                ...state,
                store_element: false,
                approving: false,
                error: null
            };

        case STORE_START:
            return {
                ...state,
                store_element: true,
                error: null,
                storing: true
            };

        case STORE_SUCCESS:
            return {
                ...state,
                store_element: false,
                error: null,
                storing: false
            };

        case STORE_ERROR:
            return {
                ...state,
                store_element: false,
                loading: false,
                error: null,
                storing: false
            };

        case CLEAN_MEASURE_ERROR:
            return {
                ...state,
                perform_measure_error: null
            };

        case PERFORM_MEASURE_START:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.

            return {
                ...state,
                measure_performing: true,
                updated_measure: null,
                error: null,
                perform_measure_error: null
            };

        case PERFORM_MEASURE_ERROR:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.

            return {
                ...state,
                measure_performing: false,
                updated_measure: null,
                perform_measure_error: action.payload.error
            };
        case PERFORM_MEASURE_FINISHED:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.

            return {
                ...state,

                updated_measure: null
            };
        case PERFORM_MEASURE_DONE:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.

            return {
                ...state,
                measure_performing: false,
                perform_measure_error: false,
                updated_measure: action.payload.updated_measure,
                error: null
            };
        case ADD_RISKROW_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.

            return {
                ...state,
                adding_risk_row: true,
                error: null
            };
        case ADD_RISKROW:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.

            return {
                ...state,
                adding_risk_row: true,
                riskrow: action.payload.riskrow,
                error: null
            };
        case ADD_RISKROW_SUCCESS:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.

            return {
                ...state,
                adding_risk_row: false,
                riskrow: null,
                posid: null,
                nodeuuid: null,
                error: null
            };

        case CHECKLIST_UPLOAD_BASIS_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.

            return {
                ...state,
                uploading_question_basis: true,
                error: null
            };

        case ADD_RISKROW_POS:
            return {
                ...state,
                posid: action.payload.posid
            };
        case CHECKLIST_UPLOAD_BASIS:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.

            return {
                ...state,
                question_basis: action.payload.basis,
                error: null
            };
        case CHECKLIST_UPLOAD_BASIS_SUCCESS:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.

            return {
                ...state,
                question_basis: null,
                uploading_question_basis: false
            };

        case RA_UPLOAD_BASIS_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.

            return {
                ...state,
                uploading_ra_basis: true,
                error: null,
                idForm: action.payload.idForm
            };
        case RA_UPLOAD_BASIS:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.

            return {
                ...state,
                ra_basis: action.payload.basis,
                error: null
            };
        case RA_UPLOAD_BASIS_SUCCESS:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.

            return {
                ...state,
                uploading_ra_basis: false,
                idForm: null
            };
        case RA_UPLOAD_BASIS_CLEAR:
            return {
                ...state,
                ra_basis: null,
                uploading_ra_basis: false,
                idForm: null
            };

        case STORE_PARTICIPANT:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.

            return {
                ...state,
                participant: action.payload.participant,
                error: null
            };

        case LOAD_QUESTIONAIRE_TEMPLATE:
            return {
                ...state,
                error: null,
                loadingTemplates: false,
                questionairetemplate: action.payload.questionairetemplate
            };

        case LOAD_QUESTIONAIRE_TEMPLATE_UPDATE_START:
            return {
                ...state,

                questionairetemplate_UPDATED: null
            };

        case LOAD_QUESTIONAIRE_TEMPLATE_UPDATE_DONE:
            return {
                ...state,
                error: null,
                questionairetemplate_UPDATED: action.payload.questionairetemplate
            };
        case CHECKLIST_SUCCESS:
            return {
                ...state,
                error: null,

                checklist: setDefaultChecklistAnswerBases(action.payload.checklist)
            };
        case CHECKLIST_LOADED:
            return {
                ...state,
                error: null,
                loading: false
            };
        case CHECKLIST_START_LOADING:
            return {
                ...state,
                error: null,
                loading: action.payload.loading
            };

        case FETCH_RA_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.

            return {
                ...state,
                error: null,
                riskassesment: null,
                loading: true,
                cover: null
            };

        case ELEMENT_CREATED:
            return {
                ...state,
                error: null,
                newElementCreated: true,
                newversionid: action.payload.versionid,
                creatingNewElement: false
            };
        case MY_TASKS_ACTION:
            if (state.mytasks) {
                state.mytasks.map((task) => {
                    action.payload.tasks.map((task1) => {
                        if (task.id == task1.id && task.visited) {
                            task1.visited = true;
                        }
                    });
                });
            }

            return {
                ...state,
                mytasks: action.payload.tasks
            };
        case ELEMENT_CREATED_AND_LOADED:
            return {
                ...state,
                newElementCreated: false,
                newversionid: null
            };
        case ELEMENT_CREATE_START:
            return {
                ...state,
                error: null,
                creatingNewElement: true,
                newElementCreated: false
            };
        case START_LOAD_QUESTINAIRE_TEMPLATES:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.

            return {
                ...state,
                error: null,
                loadingTemplates: true,
                questionairetemplates: null,
                siworkflowtemplate: null
            };

        case LOAD_QUESTINAIRE_TEMPLATES:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.

            return {
                ...state,
                error: null,
                questionairetemplates: action.payload.templateslist,
                loadingTemplates: false
            };
        case FETCH_NOTIFICATION:
            return {
                ...state,
                notification: action.payload.notification
            };

        case ADD_NEW_NOTIFICATION:
            return {
                ...state,
                notificationList: [...state.notificationList, {left: action.payload.notification, right: false}]
            };
        case FETCH_MY_NOTIFICATIONS:
            return {
                ...state,
                notificationList: action.payload.notificationList
            };

        case LOAD_CATALOG_PLACEMENT_SUCCESS:
            return {
                ...state,
                error: null,
                catalogPlacement: action.payload.catalogPlacement
            };

        case LOAD_CATALOG_PLACEMENT_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
                catalogPlacement: null
            };

        case LOAD_CATALOG_PLACEMENT_LOADED:
            return {
                ...state,
                error: null,
                loading: false
            };
        case LOAD_CATALOGS_SUCCESS:
            return {...state, catalogList: [...action.payload.catalogList]};
        case LOAD_CATALOG_SUCCESS:
            const catalog = !state.catalog ? [null, null, null, null] : [...state.catalog];
            catalog[action.payload.posId] = action.payload.catalog;

            return {
                ...state,
                error: null,
                catalog: [...catalog]
            };

        case LOAD_CATALOG_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
                loadingCatalog: true
            };

        case LOAD_CATALOG_LOADED:
            return {
                ...state,
                error: null,
                loading: false,
                loadingCatalog: false
            };

        case LOAD_CATALOGS_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
                loadingCatalog: true,
                loadingCatalogs: {
                    ...state.loadingCatalogs,
                    [action.payload]: true
                }
            };

        case LOAD_CATALOGS_LOADED:
            return {
                ...state,
                error: null,
                loading: false,
                loadingCatalog: false,
                loadingCatalogs: {
                    ...state.loadingCatalogs,
                    [action.payload]: false
                }
            };

        case LOAD_CATALOG_SUCCESS2:
            return {
                ...state,
                error: null,
                catalog2: action.payload.catalogPlacement
            };

        case LOAD_CATALOG_BEGIN2:
            return {
                ...state,
                error: null,
                loading: true,
                loadingCatalog: true
            };

        case LOAD_CATALOG_LOADED2:
            return {
                ...state,
                error: null,
                loading: false,
                loadingCatalog: false
            };

        case TASKS_LOADER_ACTION_PLAN_ON:
            return {
                ...state,
                tasksLoadingActionPlan: true
            };

        case TASKS_LOADER_ACTION_PLAN_OFF:
            return {
                ...state,
                tasksLoadingActionPlan: false
            };

        case FETCH_RA_SUCCESS:
            // Also, replace the items with the ones from the server

            return {
                ...state,
                riskassesment: action.payload.riskassesment
            };

        case FETCH_WF_RA_SUCCESS:
            // Also, replace the items with the ones from the server
            return {
                ...state,
                workflowtemplate: action.payload.workflowtemplate
            };
        case WIDTH:
            // Also, replace the items with the ones from the server
            return {
                ...state,
                width: action.width
            };
        case HEIGHT:
            // Also, replace the items with the ones from the server
            return {
                ...state,
                height: action.height
            };

        case FETCH_MEASURE_TEMPLATE_RA_SUCCESS:
            // Also, replace the items with the ones from the server
            return {
                ...state,
                measuretemplate: action.payload.measuretemplate
            };

        case FETCH_RISKROW_TEMPLATE_RA_SUCCESS:
            // Also, replace the items with the ones from the server
            return {
                ...state,
                template: action.payload.template
            };

        case FETCH_RA_OVERVIEW_SUCCESS:
            return {
                ...state,
                raoverview: action.payload.raoverview
            };

        case FETCH_RA_OVERVIEW_BEGIN:
            return {
                ...state,
                loadingoverview: action.payload.versiontid
            };
        case FETCH_RA_OVERVIEW_DONE:
            return {
                ...state,
                loadingoverview: null
            };

        case RA_ACTIONS_DISABLED:
            return {
                ...state,
                RAActionsDisabled: true
            };
        case RA_ACTIONS_ENABLED:
            return {
                ...state,
                RAActionsDisabled: false
            };
        case FETCH_RA_LOADED:
            // Also, replace the items with the ones from the server
            return {
                ...state,
                loading: false
            };
        case FETCH_ADD_MEASURE_DONE:
            // Also, replace the items with the ones from the server
            return {
                ...state,
                measure: action.payload.measure,
                add_measure_riskid: action.payload.riskid,
                loading: false
            };
        case FETCH_ADD_MEASURE_FINISH:
            // Also, replace the items with the ones from the server
            return {
                ...state,
                measure: null,
                loading: false
            };
        case FETCH_ADD_MEASURE:
            // Also, replace the items with the ones from the server
            return {
                ...state,
                loading: false
            };
        case UPDATE_EMPTY_RISK_ROW: {
            return {
                ...state,
                emptyRiskRow: action.payload.emptyRiskRow
            };
        }
        case QUESTION_FILE_REMOVE:
            return {
                ...state,
                loading: false,
                versionIdToRemove: action.payload.versionid,
                categoryIdToRemove: action.payload.categoryId,
                questionIdToRemove: action.payload.questionId,
                basisIdToRemove: action.payload.basisId
            };
        case QUESTION_FILE_REMOVE_DONE:
            return {
                ...state,
                loading: false,
                versionIdToRemove: null,
                categoryIdToRemove: null,
                questionIdToRemove: null,
                basisIdToRemove: null
            };

        case CATALOG_COUNTER_INFOS_START:
            return {
                ...state,
                loadingCounter: true,
                countedcataloginfos: null,
                node_uuid: null,
                index: -1
            };
        case CATALOG_COUNTER_INFOS_DONE:
            return {
                ...state,
                loadingCounter: false,
                countedcataloginfos: null,
                node_uuid: action.payload.node_uuid,
                index: action.payload.index
            };
        case CATALOG_COUNTER_INFOS_SUCCESS:
            return {
                ...state,
                loadingCounter: false,
                countedcataloginfos: action.payload.cataloginfos,
                node_uuid: action.payload.node_uuid,
                index: action.payload.index
            };

        case QUESTION_FILE_REMOVE_START:
            return {
                ...state,
                loading: true
            };

        case PREPARE_STATE_FOR_NEW_CHECKLIST:
            return {
                ...state,
                checklist: null,
                questionairetemplate: null,
                workflowtemplate: null,
                measuretemplate: null,
                template: null
            };
        case FETCH_RA_FAILURE:
            // The request failed, but it did stop, so set loading to "false".
            // Save the error, and we can display it somewhere
            // Since it failed, we don't have items to display anymore, so set it empty.
            // This is up to you and your app though: maybe you want to keep the items
            // around! Do whatever seems right.
            return {
                ...state,
                error: action.payload.error,
                creatingNewElement: false,
                loadingTemplates: false,
                loading: false,
                loadingoverview: false
            };

        case WANT_TO_SEE_INTRO: {
            return {
                ...state,
                wantToSeeIntro: action.payload
            };
        }
        case SET_HISTORY_UNDO_REDO: {
            return {
                ...state,
                historyUndoRedo: {...state.historyUndoRedo, ...action.payload}
            };
        }
        case SET_BLOCK: {
            return {
                ...state,
                block: {
                    ...state.block,          // зберігаємо існуючі поля
                    [action.payload.key]: action.payload.value // оновлюємо тільки вказаний ключ
                }
            };
        }
        default:
            // ALWAYS have a default case in a reducer
            return state;
    }
}

export const handleBlock = (value, key) => {
    return {
        type: "SET_BLOCK",
        payload: {value, key}
    };
};

export const handleHistoryUndoRedo = (value) => {
    return {
        type: "SET_HISTORY_UNDO_REDO",
        payload: value
    };
}


export const handleLanguage = (lng) => {
    return {
        type: "MY_LANGUAGE",
        payload: lng
    };
};

export const handleActionChildBlockHeader = (type) => {

    return {
        type: "SET_ACTION_CHILD_BLOCK_HEADER",
        payload: type
    };
}


export const setHeaderScroll = (scroll) => {
    return {
        type: "SET_HEADER_SCROLL",
        payload: scroll
    };
};

export const setCreateNewElementPatel = (value) => {
    return {
        type: "CREATE_NEW_ELEMENT_PATEL",
        payload: value
    };
}

export const setSideBarOpen = (value) => {
    return {
        type: "SET_ACTION_HEADER",
        payload: value
    };
}

export const restoreState = (restoredState) => {
    return {
        type: "RESTORE_STATE",
        payload: restoredState
    };
};

export const updateWantToSeeIntro = (value) => ({
    type: WANT_TO_SEE_INTRO,
    payload: value
});

export const tasksLoaderActionPlanOn = () => ({
    type: TASKS_LOADER_ACTION_PLAN_ON
});

export const tasksLoaderActionPlanOff = () => ({
    type: TASKS_LOADER_ACTION_PLAN_OFF
});

const setDefaultChecklistAnswerBases = (checklist) => {
    for (const key in checklist.answerAltIds) {
        if (checklist.answerAltIds.hasOwnProperty(key)) {
            checklist.answerBasises[key] = _.get(checklist.answerBasises, `${key}`, []);
        }
    }
    return checklist;
};
