import React from 'react';
import { Fade, IconButton, Tooltip } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { useElementOpen } from '../../ElementTile/utils/useElementOpen';

const useStyles = makeStyles(() => ({
  openImg: {
    width: 14,
    marginLeft: 2,
    cursor: 'pointer'
  }
}));

function OpenNewWindows({ el, blockClick, style = {} }) {
  const cl = useStyles();
  const { open } = useElementOpen();
  const intl = useIntl();

  const handleOpen = async (e) => {
    e.stopPropagation();
    if (el.elementInfo && !blockClick) {
      await open({ ...el, mimeType: el.elementInfo.mimeType, versionId: el.elementInfo.versionId });
    }
  };

  return (
    <Fade in timeout={500}>
      <div style={style}>
        <Tooltip
          title={intl.formatMessage({ id: 'OpenNewWindow', defaultMessage: 'Open in new window' })}
          placement="right"
        >
          <IconButton onClick={(e) => handleOpen(e)} size="small" sx={{maxHeight: '20px', padding: '0'}}>
            <OpenInNewIcon className={cl.openImg} />
          </IconButton>
        </Tooltip>
      </div>
    </Fade>
  );
}

export default OpenNewWindows;
