import { FormattedMessage } from 'react-intl';
import { showSnackbarChangesSaved, showSnackbarError } from '@zert-packages/components/Snackbars';
import { getPluginByMimeType } from '@zert-packages/utils/getPluginByMimeType';
import { AccountTreeTwoTone } from '@mui/icons-material';
import axiosInstance from '@zert-packages/utils/axios';
import React from 'react';
import getElementByMimeType, {
  getElementCardByMimeType
} from '@zert-packages/components/ElementTile/utils/getTileByMimeType';
import { createOpenReportSnack } from '@zert-packages/components/common/snackBar';
import axios from 'axios';
import {
  ADD_NEW_NOTIFICATION,
  CLEAN_MEASURE_ERROR,
  ELEMENT_APROVE_START,
  ELEMENT_APROVED,
  ELEMENT_CREATE_START,
  ELEMENT_CREATED,
  ELEMENT_CREATED_AND_LOADED,
  ERROR_REPORT,
  FETCH_MY_NEWS,
  FETCH_MY_NEWS_START,
  FETCH_MY_NOTIFICATIONS,
  FETCH_MY_NOTIFICATIONS_START,
  FETCH_NEWS,
  FETCH_NEWS_START,
  FETCH_NOTIFICATION,
  FETCH_NOTIFICATION_START,
  FETCH_RA_FAILURE,
  FETCH_TEMPLATES,
  MY_LOCALE,
  MY_LOCALE_FETCH,
  MY_LOCALE_STORE_DONE,
  MY_LOCALE_STORE_START,
  PERFORM_MEASURE_ERROR,
  RA_RESCENT_START,
  RA_RESCENT_SUCCESS,
  REPORT_DONE,
  REPORT_START,
  REPORT_STATUS,
  SET_OFLINE,
  SET_ONLINE,
  START_HELP_DONE,
  STORE_ERROR,
  STORE_START,
  STORE_SUCCESS,
  UPDATE_EMPTY_RISK_ROW
} from './actions';

export const API_ROOT = process.env.REACT_APP_API_ROOT;
// < Good connection > //
export const API_ROOT_CALLS = process.env.REACT_APP_API_ROOT_CALLS;

export const API_ROOT_WAR = process.env.REACT_APP_API_ROOT_WAR;

export const basisTabOverviewImage = `${API_ROOT}/riskanalises/getCoverImageById/650/150/`;

export const matrixEditorImageNoRoot = `/riskanalises/getMatrixImageById/`;
export const matrixEditorImage = `${API_ROOT}${matrixEditorImageNoRoot}`;

export const basisEditorOpenAttachment = `${API_ROOT}/checklist-react/getDocumentById/`;
export const basisEditorOpenAttachmentForPOST = '/checklist-react/getDocumentByIdAuth/';

export const coverImage = `${API_ROOT}/riskanalises/getCoverImageById/`;

export const matrixImageNoRoot = '/riskanalises/getMatrixImage/top/';
export const matrixImage = `${API_ROOT}${matrixImageNoRoot}`;

export const basisEditorAttachment = `${API_ROOT_CALLS}/checklist-react/getBasisById/`;
export const openReport = `${API_ROOT_CALLS}/report-react/openReportFile/`;
export const openReportNew = '/report-react/openReportFile/';
export const tumbPath = `${API_ROOT}/publication/getTumbImageById/`;
export const imagePath = `${API_ROOT_CALLS}/image/getImageById/`;
export const openPathBody = '/publication/getDocumentBlobById/';

const postHeader = (data) => data;

const postHeaderJsonData = (data) => data || {};

const putHeaderJsonData = (data) => data || {};

export const fetchPerformMeasureError = (error) => ({
  type: PERFORM_MEASURE_ERROR,
  payload: { error }
});

const fetchElementCreateAndLoaded = () => ({
  type: ELEMENT_CREATED_AND_LOADED
});

export function handlePostParamAsString(query, data) {
  return axiosInstance
    .put(API_ROOT_CALLS + query, data, {
      headers: {
        'Content-Length': 0,
        'Content-Type': 'text/plain'
      }
    })
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      InternalServerError.prototype = Error.prototype;
      throw new InternalServerError(error);
    });
}

export function handleFile(query) {
  return axios
    .get(query, {
      responseType: 'arraybuffer'
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      InternalServerError.prototype = Error.prototype;
      throw new InternalServerError(error);
    });
}

export function handleQueryFileAsResultHeaders(query) {
  return axiosInstance
    .get(API_ROOT_CALLS + query, {
      responseType: 'arraybuffer'
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      InternalServerError.prototype = Error.prototype;
      throw new InternalServerError(error);
    });
}

export function handleQueryFileAsResult(query) {
  return axiosInstance
    .get(API_ROOT_CALLS + query, {
      responseType: 'arraybuffer'
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      InternalServerError.prototype = Error.prototype;
      throw new InternalServerError(error);
    });
}

export function handleQueryFileAsResultWithHeaders(query) {
  return axiosInstance
    .get(API_ROOT_CALLS + query, {
      responseType: 'arraybuffer'
    })
    .then((response) => {
      return { data: response.data, headers: response.headers, config: response.config };
    })
    .catch((error) => {
      InternalServerError.prototype = Error.prototype;
      throw new InternalServerError(error);
    });
}

export function handleQueryBlobAsResult(query) {
  return handleQueryFileAsResult(query).then((data) => {
    return new Blob([data]);
  });
}

export function handlePostTextPostFileAsResult(query, data) {
  return axiosInstance
    .put(API_ROOT_CALLS + query, data, {
      headers: {
        'Content-Length': 0,
        'Content-Type': 'text/plain'
      },
      responseType: 'arraybuffer'
    })
    .then((response) => {
      return response;
    });
}

export function handlePostFileAsResult(query, data) {
  return axiosInstance
    .put(API_ROOT_CALLS + query, data, {
      responseType: 'arraybuffer'
    })
    .then((response) => {
      return response.data;
    });
}

export function handlePostTextAsParam(query, data) {
  return (
    axiosInstance
      .put(API_ROOT_CALLS + query, data, {
        headers: {
          'Content-Length': 0,
          'Content-Type': 'text/plain'
        }
      })
      // .then(handleErrors)
      .then((response) => {
        return response.data;
      })
  );
}

export function handlePostTextAsParamResultAsText(query, data) {
  return axiosInstance
    .post(API_ROOT_CALLS + query, data, {
      headers: {
        'Content-Length': 0,
        'Content-Type': 'text/plain'
      },
      responseType: 'text'
    })
    .then((response) => {
      return response.data;
    });
}

export function handlePostResultAsString(query, data) {
  return axiosInstance
    .put(API_ROOT_CALLS + query, postHeader(data), {
      headers: {
        'Content-Length': 0,
        'Content-Type': 'application/json'
      },
      responseType: 'text'
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      InternalServerError.prototype = Error.prototype;
      throw new InternalServerError(error);
    });
}

function InternalServerError(property) {
  this.name = property && property.type ? property.type : 'InternalServerError';

  this.property = property;
  this.message = property.message;
  this.type = property.type ? property.type : property.status;
  if (this.property.trace) {
    this.stack = this.property.trace;
  } else if (Error.captureStackTrace) {
    Error.captureStackTrace(this, InternalServerError);
  } else {
    this.stack = new Error().stack;
  }
}

export function handlePost(query, data) {
  return axiosInstance
    .put(API_ROOT_CALLS + query, postHeaderJsonData(data), {
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'json'
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      InternalServerError.prototype = Error.prototype;
      throw new InternalServerError(error);
    });
}

export function handlePostToApi(query, data) {
  return axiosInstance
    .post(API_ROOT_CALLS + query, postHeaderJsonData(data), {
      headers: {
        'Content-Length': 0,
        'Content-Type': 'application/json'
      },
      responseType: 'json'
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      InternalServerError.prototype = Error.prototype;
      throw new InternalServerError(error);
    });
}

// Native fetch query since axios does not support streaming response
export async function handlePostToApiStreamResponse(query, data) {
  const accessToken = localStorage.getItem('accessToken');

  const response = await fetch(API_ROOT_CALLS + query, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {})
    },
    body: JSON.stringify(data)
  });

  return response;
}

export function handlePatchToApi(query, data) {
  return axiosInstance
    .patch(API_ROOT_CALLS + query, postHeaderJsonData(data), {
      headers: {
        'Content-Length': 0,
        'Content-Type': 'application/json'
      },
      responseType: 'json'
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      InternalServerError.prototype = Error.prototype;
      throw new InternalServerError(error);
    });
}

export function handleGetToApi(query, data) {
  return axiosInstance
    .get(API_ROOT_CALLS + query, postHeaderJsonData(data))
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      InternalServerError.prototype = Error.prototype;
      throw new InternalServerError(error);
    });
}

export function handlePutToApi(query, data) {
  return axiosInstance
    .put(API_ROOT_CALLS + query, putHeaderJsonData(data), {
      headers: {
        'Content-Length': 0,
        'Content-Type': 'application/json'
      },
      responseType: 'json'
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      InternalServerError.prototype = Error.prototype;
      throw new InternalServerError(error);
    });
}

export function handleDeleteToApi(query, data) {
  return axiosInstance
    .delete(API_ROOT_CALLS + query, {
      headers: {
        'Content-Length': 0,
        'Content-Type': 'application/json'
      },
      data,
      responseType: 'json'
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      InternalServerError.prototype = Error.prototype;
      throw new InternalServerError(error);
    });
}

export const fetchRA_Error = (error) => {
  if (!(error instanceof InternalServerError)) {
    return {
      type: SET_OFLINE,
      payload: { error }
    };
  }

  return {
    type: FETCH_RA_FAILURE,
    payload: { error }
  };
};

export const fetchMyNews = (mynews) => ({
  type: FETCH_MY_NEWS,
  payload: { mynews }
});

export const fetchAllTemplates = (templates) => ({
  type: FETCH_TEMPLATES,
  payload: { templates }
});

export const fetchMyNewsStart = () => ({
  type: FETCH_MY_NEWS_START,
  payload: {}
});

export const fetchNews = (news) => ({
  type: FETCH_NEWS,
  payload: { news }
});

const fetchNewsStart = (versionId) => ({
  type: FETCH_NEWS_START,
  payload: { versionId }
});

export const fetchMyNotifications = (notificationList) => ({
  type: FETCH_MY_NOTIFICATIONS,
  payload: { notificationList }
});

export const addNewNotification = (notification) => ({
  type: ADD_NEW_NOTIFICATION,
  payload: { notification }
});

const fetchMyNotificationsStart = () => ({
  type: FETCH_MY_NOTIFICATIONS_START,
  payload: {}
});

export const fetchNotificationById = (notification) => ({
  type: FETCH_NOTIFICATION,
  payload: { notification }
});

const fetchNotificationByIdStart = () => ({
  type: FETCH_NOTIFICATION_START,
  payload: {}
});

export function registerToken(token) {
  return handlePostParamAsString('/common/registerToken', token);
}

export const fetchSTORE_Error = (error) => ({
  type: STORE_ERROR,
  payload: { error }
});

export const fetchRA_Rescent_Success = (riskanalises) => ({
  type: RA_RESCENT_SUCCESS,
  payload: { riskanalises }
});

export const fetchRA_Rescent_Begin = () => ({
  type: RA_RESCENT_START,
  payload: {}
});

export const fetchElementAproveStart = () => ({
  type: ELEMENT_APROVE_START,
  payload: {}
});

export const fetchElementAproved = () => ({
  type: ELEMENT_APROVED,
  payload: {}
});

export const fetchElementCreatedDone = (versionid) => ({
  type: ELEMENT_CREATED,
  payload: { versionid }
});

export function fetchElementCreated(versionId, attachedToVersionId, activityId) {
  return function (dispatch) {
    if (attachedToVersionId) {
      handleQuery(`/common/attachContainer/${versionId}/${attachedToVersionId}/${activityId}`).then(() => {
        dispatch(fetchElementCreatedDone(versionId));
      });
    } else {
      dispatch(fetchElementCreatedDone(versionId));
    }
  };
}

export const fetchElementCreate = () => ({
  type: ELEMENT_CREATE_START
});

const fetchHelpDone = (helpText, helpId) => ({
  type: START_HELP_DONE,
  payload: { helpText, helpId }
});

// export const basisEditorOpenAttachment = API_ROOT_CALLS + '/checklist-react/getDocumentById/';

export const loadHelp =
  (helpid) =>
  async (dispatch, getState, { snackbar }) => {
    const mylocale = getState().mylocale ? getState().mylocale : 'sv';
    let help = '';
    try {
      help = (await axiosInstance.get(`/help/${helpid}_${mylocale}.html`)).data;
    } catch (e) {
      try {
        help = (await axiosInstance.get(`/help/${helpid}.html`)).data;
      } catch (e) {}
    }
    dispatch(fetchHelpDone(help, helpid));
  };

export const reportDone = (report) => ({
  type: REPORT_DONE,
  payload: { report }
});

export const fetchReportError = (error) => ({
  type: ERROR_REPORT,
  payload: { error }
});

export const fetchReportStart = (report) => ({
  type: REPORT_START,
  payload: { report }
});

export const fetchReportStatus = (callback) => ({
  type: REPORT_STATUS,
  payload: { callback }
});

export const fetchMyLocale = (locale) => ({
  type: MY_LOCALE,
  payload: { locale }
});

export const REDUCER_SET_ONLINE = () => ({
  type: SET_ONLINE,
  payload: {}
});

export const REDUCER_SET_OFLINE = () => ({
  type: SET_OFLINE,
  payload: {}
});

export const fetchStoreMyLocaleStart = () => ({
  type: MY_LOCALE_STORE_START,
  payload: {}
});

export const fetchStoreMyLocaleDone = (locale) => ({
  type: MY_LOCALE_STORE_DONE,
  payload: { locale }
});

export const fetchMyStartLocale = () => ({
  type: MY_LOCALE_FETCH,
  payload: {}
});

export const checkReportStatus = (report, snackbar, generatingSnackbar, generatedReport, mimeType) => {
  handleQuery(`/report-react/updateProgressStatus/${report.callbackId}/false`)
    .then((newReport) => {
      if (newReport !== null && newReport.active) {
        checkReportStatus(newReport, snackbar, generatingSnackbar, generatedReport, mimeType);
      } else {
        snackbar.closeSnackbar(generatingSnackbar);
        createOpenReportSnack(snackbar, generatedReport.reportUUID, mimeType);
      }
    })
    .catch((error) => {
      snackbar.closeSnackbar(generatingSnackbar);
      showSnackbarError(snackbar, error);
    });
};

export function updateWorkerStatus(info, { snackbar }, skipException) {
  return handleQuery(`/report-react/updateProgressStatus/${info.right.callbackId}/false`)
    .then((result) => {
      if (result != null && result.active) {
        return updateWorkerStatus(info, { snackbar });
      }
      return info.left;
    })
    .catch((error) => {
      if (skipException) {
        throw error;
      } else {
        showSnackbarError(snackbar, error.message);
      }
    });
}

export function updateProgressStatus(callback, report) {
  return function (dispatch, getState, hooks) {
    handleQuery(`/report-react/updateProgressStatus/${callback.callbackId}/false`)
      .then((result) => {
        if (result != null && result.active) {
          dispatch(fetchReportStatus(result));
          dispatch(updateProgressStatus(result, report));
        } else {
          report.done = true;
          dispatch(reportDone(report));
        }
      })
      .catch((error) => {
        showSnackbarError(hooks.snackbar, error.message);
        dispatch(fetchReportError(error));
      });
    return fetchReportStatus(callback);
  };
}

export function handlePostFile(query, data) {
  return axiosInstance
    .post(API_ROOT_CALLS + query, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.message.indexOf('Unexpected end of JSON input') !== -1) {
        return null;
      }
      if (error.message.indexOf('<html>') == 0) {
        window.location.href = '/app/login';
      }
      if (error instanceof TypeError && error.message.indexOf('Failed to fetch') == 0) {
        throw error;
      } else {
        InternalServerError.prototype = Error.prototype;
        throw new InternalServerError(error);
      }
    });
}

export function handleText(query) {
  return axiosInstance
    .get(API_ROOT_CALLS + query)

    .then((response) => {
      return response.data;
    });
}

export function storeMyUser(data) {
  return function (dispatch, getState, hooks) {
    handlePost('/common/storeMyUser/', data)
      .then((id) => {
        showSnackbarChangesSaved(hooks.snackbar);
        dispatch(fetchElementStored(id));
      })
      .catch((error) => dispatch(fetchSTORE_Error(error)));
    return dispatch(fetchElementStoreStart());
  };
}

export function storeMyLocale(locale) {
  return function (dispatch) {
    handleText(`/common/storeMyLocale/${locale}`)
      .then((id) => {
        dispatch(fetchStoreMyLocaleDone(locale));
        window.location.reload();
      })
      .catch((error) => dispatch(fetchSTORE_Error(error)));
    return dispatch(fetchStoreMyLocaleStart());
  };
}

export function loadMyLocale() {
  return function (dispatch) {
    // dispatch(fetchMyLocale());
    handleText('/common/loadMyLocale')
      .then((myLocale) => {
        //       document.documentElement.lang = myLocale;
        dispatch(fetchMyLocale(myLocale));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchMyStartLocale());
  };
}

export function getAllTemplates() {
  return function (dispatch) {
    handleQuery('/common/getAllTemplates')
      .then((allTemplates) => {
        dispatch(fetchAllTemplates(allTemplates));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
  };
}

export function getMyNews(nextVersionId) {
  return function (dispatch) {
    handleQuery(`/common/getMyNews/${nextVersionId}`)
      .then((myNews) => {
        dispatch(fetchMyNews(myNews));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchMyNewsStart());
  };
}

export function getMyNotifications() {
  return function (dispatch) {
    handleQuery('/common/getMyNotifications')
      .then((notificationList) => {
        dispatch(fetchMyNotifications(notificationList));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchMyNotificationsStart());
  };
}

export function getNotificationById(id) {
  return function (dispatch) {
    handleQuery(`/common/getNotification/${id}`)
      .then((notification) => {
        dispatch(fetchNotificationById(notification));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchNotificationByIdStart());
  };
}

export function getNews(versionId) {
  return function (dispatch) {
    handleQuery(`/common/getNews/${versionId}`)
      .then((news) => {
        dispatch(fetchNews(news));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchNewsStart(versionId));
  };
}

/*
const myRequest = new Request('flowers.jpg', myInit);
const response = await fetch(myRequest);
const myBlob = await response.blob();
const objectURL = URL.createObjectURL(myBlob);
myImage.src = objectURL; */

export function handleQuery(query, notCheckError) {
  return axiosInstance
    .get(API_ROOT_CALLS + query)

    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      InternalServerError.prototype = Error.prototype;
      throw new InternalServerError(error);
    });
}

export function getMatrixEditorImage(templateVersionId, versionId, locale, columnId, position, generate = false) {
  const queryStr =
    `${matrixEditorImageNoRoot}${templateVersionId}/` + `${versionId}/${generate}/${locale}/${position}/${columnId}`;
  return handleQueryBlobAsResult(queryStr);
}

export function moveAfterDelivery(versionId, history, dispatch, activityId) {
  handleQuery(`/common/versionInfo/${versionId}`).then((version) => {
    history.push(
      `${API_ROOT_WAR}/basis${getPluginByMimeType(version.info.mimeType)
        .openpath.replace(':versionId', versionId)
        .replace(API_ROOT_WAR, '')}${activityId && activityId != 'basis' ? `/${activityId}` : ''}`
    );
    dispatch(elementCreatedAndLoaded());
  });
}

export function searchParams(name) {
  const results = new RegExp(`[\?&]${name}=([^&#]*)`).exec(window.location.href);
  if (results == null) {
    return null;
  }
  return decodeURI(results[1]) || 0;
}

export const updateEmptyRiskRow = (emptyRiskRow) => ({
  type: UPDATE_EMPTY_RISK_ROW,
  payload: {
    emptyRiskRow
  }
});

export const fetchElementStored = (id) => ({
  type: STORE_SUCCESS,
  payload: { id }
});

export const fetchElementStoreStart = () => ({
  type: STORE_START,
  payload: {}
});

export const cleanMeasureError = () => ({
  type: CLEAN_MEASURE_ERROR,
  payload: {}
});

export async function handleErrors(response) {
  if ((response && !response.ok && response.status != 307) || response.status == 303) {
    return response.text();
  }
  if (response && !response.ok && response.status == 307) {
    return response.json();
  }
  return response;
}

export function elementCreatedAndLoaded() {
  return function (dispatch) {
    return dispatch(fetchElementCreateAndLoaded());
  };
}

let store = null;

let messages = [];

var plugins = null;

export function getMessages() {
  return messages;
}

export function pushMessages(messagesNew) {
  messages = messagesNew;
}

export function getStore() {
  return store;
}

export function pushStore(storeNew) {
  store = storeNew;
}

export function pushPlugins(exported) {
  plugins = exported;
}

export function getPlugins() {
  return plugins;
}

export function getPluginsList() {
  const data = getPlugins()
    .reduce((acum, plugin) => {
      return [...acum, plugin.newpermit, plugin.readpermit, plugin.editpermit];
    }, [])
    .filter((plugin) => plugin != null);
  return [
    ...data,
    'zert-explorer/explorer',
    'zert-catalog/catalog-explorer',
    'zert-search/search',
    'zert-administration/configure',
    'zert-administration/archive',
    'zert-administration/delete',
    'zert-administration/create-shortcuts',
    'zert-administration/provisioning-management',
    'zert-document/edit-document-template'
  ];
}

export function getPluginsOverview(permits) {
  const minePlugins1 = getPlugins().filter(
    (plugin) => plugin.routes && plugin.routes.find((route) => route.type && route.isOverview)
  );

  const minePlugins2 = minePlugins1
    .reduce((acum, plugin) => {
      return [
        ...plugin.routes
          .filter((route) => route.isOverview && (!plugin.readpermit || permits[plugin.readpermit]))
          .map((route) => ({
            to: route.path,
            name: route.sidebarTitle != null ? route.sidebarTitle : route.title,
            icon: plugin.icon,
            order: plugin.order
          })),
        ...acum
      ];
    }, [])
    .sort((item1, item2) => {
      if (item1.order > item2.order) {
        return 1;
      }
      if (item1.order < item2.order) {
        return -1;
      }
      return 0;
    });

  return minePlugins2;
}

export function getAllTaskPluginsAndWildCard() {
  const taskplugins = getPlugins().filter((plugin) => plugin.isTask);
  const taskDefinitions = taskplugins.map((plugin, index) => {
    return {
      id: index + 1,
      title: {
        text: plugin.name
      },
      mimeType: plugin.mimeType
    };
  });
  taskDefinitions.push({
    id: 0,
    title: {
      text: <FormattedMessage id="environment.title.allmimetypes" defaultMessage="All" />
    },
    mimeType: '*'
  });
  return taskDefinitions;
}

export function getAllRoutes() {
  const minePlugins = getPlugins()
    .filter((plugin) => plugin.routes)
    .reduce((acum, plugin) => {
      return [
        ...plugin.routes.map((route) => ({ to: route.path, name: route.title, plugin, helpId: route.helpId })),
        ...acum
      ];
    }, []);

  return minePlugins;
}

export function getCatalogs(catalogList) {
  if (catalogList == null) {
    return [];
  }

  return catalogList
    .map((catalog, index) => {
      if (catalog) {
        return {
          order: 7 + index,
          to: `/catalog${index}`,
          name: catalog.name,

          icon: AccountTreeTwoTone
        };
      }
      return null;
    })
    .filter((catalog) => catalog != null);
}

let tilesFunc = getElementByMimeType;

let cardFunc = getElementCardByMimeType;

export function getTilesFunc() {
  return tilesFunc;
}

export function setTilesFunc(func) {
  tilesFunc = func;
}

export function getСardFunc() {
  return cardFunc;
}

export function setCardFunc(func) {
  cardFunc = func;
}

let previewFunc = null;

export function getPreviewFunc() {
  return previewFunc;
}

export function setPreviewFunc(func) {
  previewFunc = func;
}

let publishFunc = null;

export function getPublishFunc() {
  return publishFunc;
}

export function setPublishFunc(func) {
  publishFunc = func;
}
