export const FETCH_RA_BEGIN = 'FETCH_RA_BEGIN';
export const FETCH_RA_SUCCESS = 'FETCH_RA_SUCCESS';
export const FETCH_WF_RA_SUCCESS = 'FETCH_WF_RA_SUCCESS';
export const FETCH_MEASURE_TEMPLATE_RA_SUCCESS = 'FETCH_MEASURE_TEMPLATE_RA_SUCCESS';
export const FETCH_RISKROW_TEMPLATE_RA_SUCCESS = 'FETCH_RISKROW__TEMPLATE_RA_SUCCESS';
export const FETCH_RA_OVERVIEW_SUCCESS = 'FETCH_RA_OVERVIEW_SUCCESS';
export const FETCH_RA_OVERVIEW_BEGIN = 'FETCH_RA_OVERVIEW_BEGIN';
export const FETCH_RA_OVERVIEW_DONE = 'FETCH_RA_OVERVIEW_DONE';
export const FETCH_RA_FAILURE = 'FETCH_RA_FAILURE';
export const FETCH_RA_LOADED = 'FETCH_RA_LOADED';
export const FETCH_RA_IDENTIFIER_DONE = 'FETCH_RA_FAILURE';
export const WIDTH = 'WIDTH';
export const HEIGHT = 'HEIGHT';
export const FETCH_ADD_MEASURE = 'ADD_MEASURE_BEGIN';
export const FETCH_ADD_MEASURE_DONE = 'ADD_MEASURE_DONE';
export const FETCH_ADD_MEASURE_FINISH = 'ADD_MEASURE_FINISH';
export const CATALOG_COUNTER_INFOS_START = 'CATALOG_COUNTER_INFOS_START';
export const CATALOG_COUNTER_INFOS_DONE = 'CATALOG_COUNTER_INFOS_DONE';
export const CATALOG_COUNTER_INFOS_SUCCESS = 'CATALOG_COUNTER_INFOS_SUCCESS';
export const LOAD_QUESTINAIRE_TEMPLATES = 'LOAD_QUESTINAIRE_TEMPLATES';
export const START_LOAD_QUESTINAIRE_TEMPLATES = 'START_LOADING_QUESTINAIRE_TEMPLATES';
export const ELEMENT_CREATED = 'ELEMENT_CREATED';
export const ELEMENT_CREATE_START = 'ELEMENT_CREATE_START';
export const ELEMENT_CREATED_AND_LOADED = 'ELEMENT_CREATED_AND_LOADED';
export const LOAD_QUESTIONAIRE_TEMPLATE = 'LOAD_QUESTIONAIRE_TEMPLATE';
export const CHECKLIST_SUCCESS = 'FETCH_CHECKLIST_SUCCESS';
export const CHECKLIST_LOADED = 'CHECKLIST_LOADED';
export const CHECKLIST_START_LOADING = 'CHECKLIST_START_LOADING';
export const STORE_PARTICIPANT = 'STORE_PARTICIPANT';
export const CHECKLIST_UPLOAD_BASIS_BEGIN = 'CHECKLIST_UPLOAD_BASIS_BEGIN';
export const CHECKLIST_UPLOAD_BASIS = 'CHECKLIST_UPLOAD_BASIS';
export const CHECKLIST_UPLOAD_BASIS_SUCCESS = 'CHECKLIST_UPLOAD_BASIS_SUCCESS';
export const ADD_RISKROW_BEGIN = 'ADD_RISKROW_BEGIN';
export const ADD_RISKROW = 'ADD_RISKROW';
export const ADD_RISKROW_SUCCESS = 'ADD_RISKROW_SUCCESS';
export const CHECKLIST_STORE_SUCCESS = 'CHECKLIST_STORE_SUCCESS';
export const CHECKLIST_STORE_START = 'CHECKLIST_STORE_START';
export const PERFORM_MEASURE_START = 'PERFORM_MEASURE_START';
export const PERFORM_MEASURE_DONE = 'PERFORM_MEASURE_DONE';
export const PERFORM_MEASURE_FINISHED = 'PERFORM_MEASURE_FINISHED';
export const PERFORM_MEASURE_ERROR = 'PERFORM_MEASURE_ERROR';
export const CLEAN_MEASURE_ERROR = 'CLEAN_MEASURE_ERROR';
export const RA_UPLOAD_BASIS_BEGIN = 'RA_UPLOAD_BASIS_BEGIN';
export const RA_UPLOAD_BASIS_CLEAR = 'RA_UPLOAD_BASIS_CLEAR';
export const RA_UPLOAD_BASIS = 'RA_UPLOAD_BASIS';
export const RA_UPLOAD_BASIS_SUCCESS = 'RA_UPLOAD_BASIS_SUCCESS';
export const FETCH_QUESTINAIRE_TEMPLATE_SUCCESS = 'FETCH_QUESTINAIRE_TEMPLATE_SUCCESS';
export const FETCH_QUESTINAIRE_TEMPLATE_BEGIN = 'FETCH_QUESTINAIRE_TEMPLATE_BEGIN';
export const FETCH_WF_SI_SUCCESS = 'FETCH_WF_SI_SUCCESS';
export const SafetyInspection_START_LOADING = 'SafetyInspection_START_LOADING';
export const SafetyInspection_LOADED = 'SafetyInspection_LOADED';
export const SafetyInspection_SUCCESS = 'SafetyInspection_SUCCESS';
export const LOAD_QUESTIONAIRE_TEMPLATE_UPDATE_DONE = 'LOAD_QUESTIONAIRE_TEMPLATE_UPDATE_DONE';
export const LOAD_QUESTIONAIRE_TEMPLATE_UPDATE_START = 'LOAD_QUESTIONAIRE_TEMPLATE_UPDATE_START';
export const PERFORM_SafetyInspection_DONE = 'PERFORM_SafetyInspection_DONE';
export const ELEMENT_APROVE_START = 'ELEMENT_APROVE_START';
export const ELEMENT_APROVED = 'ELEMENT_APROVED';

export const FETCH_DEVIATION_TEMPLATES_SUCCESS = 'FETCH_DEVIATION_TEMPLATES_SUCCESS';
export const FETCH_DEVIATION_TEMPLATE_BEGIN = 'FETCH_DEVIATION_TEMPLATE_BEGIN';
export const FETCH_DEVIATION_TEMPLATE_SUCCESS = 'FETCH_DEVIATION_TEMPLATE_SUCCESS';
export const FETCH_DEVIATION_TEMPLATES_BEGIN = 'FETCH_DEVIATION_TEMPLATES_BEGIN';
export const FETCH_DEVIATION_TEMPLATE_WORKFLOW_SUCCESS = 'FETCH_DEVIATION_TEMPLATE_WORKFLOW_SUCCESS';
export const FETCH_ACTION_PLAN_SUCCESS = 'FETCH_ACTION_PLAN_SUCCESS';
export const FETCH_ACTION_PLAN_BEGIN = 'FETCH_ACTION_PLAN_BEGIN';
export const FETCH_ACTION_PLAN_MYJOB_SUCCESS = 'FETCH_ACTION_PLAN_MYJOB_SUCCESS';

export const LOAD_CATALOG_PLACEMENT_SUCCESS = 'LOAD_CATALOG_PLACEMENT_SUCCESS';
export const LOAD_CATALOG_PLACEMENT_BEGIN = 'LOAD_CATALOG_PLACEMENT_BEGIN';
export const LOAD_CATALOG_PLACEMENT_LOADED = 'LOAD_CATALOG_PLACEMENT_LOADED';
export const MY_TASKS_ACTION = 'MY_TASKS_ACTION';

export const LOAD_CATALOG_SUCCESS = 'LOAD_CATALOG_SUCCESS';
export const LOAD_CATALOGS_SUCCESS = 'LOAD_CATALOGS_SUCCESS';

export const LOAD_CATALOG_BYID_SUCCESS = 'LOAD_CATALOG_BYID_SUCCESS';

export const LOAD_CATALOGS_BEGIN = 'LOAD_CATALOGS_BEGIN';
export const LOAD_CATALOGS_LOADED = 'LOAD_CATALOGS_LOADED';

export const LOAD_CATALOG_BEGIN = 'LOAD_CATALOG_BEGIN';
export const LOAD_CATALOG_LOADED = 'LOAD_CATALOG_LOADED';

export const LOAD_CATALOG_SUCCESS2 = 'LOAD_CATALOG_SUCCESS2';
export const LOAD_CATALOG_BEGIN2 = 'LOAD_CATALOG_BEGIN2';
export const LOAD_CATALOG_LOADED2 = 'LOAD_CATALOG_LOADED2';

export const LOAD_CATALOG_NODE_START = 'LOAD_CATALOG_NODE_START';
export const LOAD_CATALOG_DATA_SUCCESS = 'LOAD_CATALOG_DATA_SUCCESS';
export const FETCH_RISKROW_TEMPLATES_SUCCESS = 'FETCH_RISKROW_TEMPLATES_SUCCESS';
export const FETCH_RISKROW_TEMPLATES_START = 'FETCH_RISKROW_TEMPLATES_START';

export const FETCH_ACTION_PLAN_WORKLFOW_SUCCES = 'FETCH_ACTION_PLAN_WORKLFOW_SUCCES';
export const FETCH_ACTION_PLAN_WORKLFOW_BEGIN = 'FETCH_ACTION_PLAN_WORKLFOW_BEGIN';
export const FETCH_TASK_PERFROM_BEGIN = 'FETCH_TASK_PERFROM_BEGIN';
export const FETCH_TASK_PERFROM_SUCCESS = 'FETCH_TASK_PERFROM_SUCCESS';
export const FETCH_TASK_PERFROM_DONE = 'FETCH_TASK_PERFROM_DONE';
export const FETCH_DEVIATION_SUCCESS = 'FETCH_DEVIATION_SUCCESS';
export const FETCH_DEVIATION_BEGIN = 'FETCH_DEVIATION_BEGIN';
export const STORE_SUCCESS = 'STORE_SUCCESS';
export const STORE_START = 'STORE_START';
export const STORE_ERROR = 'STORE_ERROR';
export const ACTION_PLAN_TASK_COUNT = 'ACTION_PLAN_TASK_COUNT';
export const ACTION_PLAN_TASK_PLANNED_COUNT = 'ACTION_PLAN_TASK_PLANNED_COUNT';

export const RESTORE_STATE = 'RESTORE_STATE';

export const ACTION_PLAN_PAGE = 'ACTION_PLAN_PAGE';
export const FETCH_DEVIATION_PERFORMED = 'FETCH_DEVIATION_PERFORMED';
export const FETCH_DEVIATION_PERFORM = 'FETCH_DEVIATION_PERFORM';
export const FETCH_MEASURE_BEGIN = 'FETCH_MEASURE_BEGIN';
export const FETCH_MEASURE_SUCCESS = 'FETCH_MEASURE_SUCCESS';
export const FETCH_MEASURE_DONE = 'FETCH_MEASURE_DONE';
export const RA_RESCENT_SUCCESS = 'RA_RESCENT_SUCCESS';
export const RA_RESCENT_START = 'RA_RESCENT_START';
export const START_HELP_FETCH = 'START_HELP_FETCH';
export const START_HELP_DONE = 'START_HELP_DONE';
export const ADD_RISKROW_POS = 'ADD_RISKROW_POS';
export const ADD_RISKROW_NODE = 'ADD_RISKROW_NODE';
export const FOCUS_RISKROW = 'FOCUS_RISKROW';
export const RA_ADD_NODE = 'RA_ADD_NODE';
export const RA_ADD_NODE_UUID = 'RA_ADD_NODE_UUID';

export const RA_ACTIONS_DISABLED = 'RA_ACTIONS_DISABLED';
export const RA_ACTIONS_ENABLED = 'RA_ACTIONS_ENABLED';

export const RA_COVER_UPLOAD_BEGIN = 'RA_COVER_UPLOAD_BEGIN';
export const RA_COVER_UPLOAD_BASIS = 'RA_COVER_UPLOAD_BASIS';
export const RA_COVER_UPLOAD_SUCCESS = 'RA_COVER_UPLOAD_SUCCESS';
export const REPORT_DONE = 'REPORT_DONE';
export const ERROR_REPORT = 'ERROR_REPORT';
export const REPORT_START = 'REPORT_START';
export const REPORT_STATUS = 'REPORT_STATUS';
export const LOAD_CATALOG_COUNTERS_SUCCESS = 'LOAD_CATALOG_COUNTERS_SUCCESS';
export const LOAD_CATALOG_COUNTERS_START = 'LOAD_CATALOG_COUNTERS_START';
export const MY_LOCALE = 'MY_LOCALE';
export const MY_LANGUAGE = 'MY_LANGUAGE';
export const MY_LOCALE_FETCH = 'MY_LOCALE_FETCH';
export const MY_LOCALE_STORE_START = 'MY_LOCALE_STORE_START';
export const MY_LOCALE_STORE_DONE = 'MY_LOCALE_STORE_DONE';
export const MY_USER_FETCH = 'MY_USER_FETCH';
export const MY_USER_FETCH_START = 'MY_USER_FETCH_START';
export const FETCH_MY_NEWS_START = 'FETCH_MY_NEWS_START';
export const FETCH_MY_NEWS = 'FETCH_MY_NEWS';
export const FETCH_TEMPLATES = 'FETCH_TEMPLATES';
export const FETCH_MY_NOTIFICATIONS = 'FETCH_MY_NOTIFICATIONS';
export const ADD_NEW_NOTIFICATION = 'ADD_NEW_NOTIFICATION';
export const FETCH_MY_NOTIFICATIONS_START = 'FETCH_MY_NOTIFICATIONS_START';

export const FETCH_NOTIFICATION = 'FETCH_NOTIFICATION';
export const FETCH_NOTIFICATION_START = 'FETCH_NOTIFICATION_START';

export const FETCH_NEWS = 'FETCH_NEWS';
export const FETCH_NEWS_START = 'FETCH_NEWS_START';
export const RA_ADD_NODE_DONE = 'RA_ADD_NODE_DONE';

export const FETCH_COPY_ELEMENTS = 'FETCH_COPY_ELEMENTS';
export const FETCH_CUT_ELEMENTS = 'FETCH_CUT_ELEMENTS';
export const FETCH_PASTE_ELEMENTS = 'FETCH_PASTE_ELEMENTS';
export const QUESTION_FILE_REMOVE_START = 'QUESTION_FILE_REMOVE_START';
export const QUESTION_FILE_REMOVE = 'QUESTION_FILE_REMOVE';

export const QUESTION_FILE_REMOVE_DONE = 'QUESTION_FILE_REMOVE_DONE';

export const WANT_TO_SEE_INTRO = 'WANT_TO_SEE_INTRO';

export const TASKS_LOADER_ACTION_PLAN_ON = 'TASKS_LOADER_ACTION_PLAN_ON';
export const TASKS_LOADER_ACTION_PLAN_OFF = 'TASKS_LOADER_ACTION_PLAN_OFF';

export const UPDATE_EMPTY_RISK_ROW = 'UPDATE_EMPTY_RISK_ROW';

export const SET_OFLINE = 'SET_OFLINE';
export const SET_ONLINE = 'SET_ONLINE';

export const SET_HEADER_SCROLL = 'SET_HEADER_SCROLL';
export const SET_ACTION_HEADER = 'SET_ACTION_HEADER';
export const CREATE_NEW_ELEMENT_PATEL = 'CREATE_NEW_ELEMENT_PATEL';
export const SET_BLOCK = 'SET_BLOCK';
export const SET_ACTION_CHILD_BLOCK_HEADER = 'SET_ACTION_CHILD_BLOCK_HEADER';
export const SET_HISTORY_UNDO_REDO = 'SET_HISTORY_UNDO_REDO'
