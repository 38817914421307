export const dataContext = {
    loadingSave: false,
    beforeTreeItem: [],
    valuesName: '',
    localesFilters: null,
    publishingFilters: null,
    treeChildren: null,
    scrollTop: false,
    parentItem: false,
    parentItemChild: null,
    treeItem: null,
    treeItemCollapse: null,
    severalSelectedTreeItem: null,
    createItem: null,
    closeChild: false,
    investmentLevel: 0,
    dropBlockTable: null,
    dropBlockTableCloseChild: false,
    addImgPhraseType: null,
    selectedId: -10,
    blockKey: false,
    blockKeyRedactor: false,
    getTreeItemChild: null,
    blockTreeItemLeftMenu: false,
    parentSelectedLvlTrue: false,
    treeItemDelete: null,
    copyBlock: {
        blockItems: null,
        blockItemsParent: null
    },
    stepperKey: [],
    stepperKeyIndex: -1,
    blockClickCreateItemsBlock: false,
    tableImg: null,
    dropBlockPosition: {
        top: 0,
        width: 100
    },
    changesEditor: false,
    socket: [],
    closeAllBlockItems: false,
    parentBlock: null,
    pasteMainParent: null,
    copyMainParent: null,
    searchNawBar: {
        searchText: '',
        searchTextItems: [],
        activeItem: null,
        selectedIndexText: -1
    },
    action: {
        preSaveElement: null,
        saveElement: null,
        parentItemCreate: null,
        indexElementCreate: null,
        createElementText: null
    },
    actionTable: null,
    pastItem: null,
    expandedTreeItem: null,
    translatable: false,
    selectedParentFirstLoad: false,
    checkin: null,
    changeBlock: false,
    addPublishingFilters: null,
};
