import {
  handlePatchToApi,
  handlePost,
  handlePostResultAsString,
  handlePostTextAsParam,
  handlePostTextAsParamResultAsText,
  handlePostToApi,
  handleQuery
} from '@zert-packages/actions/api';
import { API_ROOT_CALLS } from 'clm/src/plugins/BlockPlugin/API';
import axios from '@zert-packages/utils/axios';

export const savePhraseApi = (versionId, data) => {
  return handlePostResultAsString(`/phrase/storeBlockPhraseFormatted/${versionId}`, data);
};

export const saveIndexApi = (versionId, data) => {
  return handlePostResultAsString(`/index/storeBlockIndex/${versionId}`, data);
};

export const setTerm = (versionId, phraseVersionId, data = {}) => {
  return handlePostResultAsString(`/softwareText/setTerm/${versionId}/${phraseVersionId}`, data);
};

// table start

export const createPhraseTable = (versionId) => {
  return handlePostResultAsString(`/phrase/storeBlockPhraseFormatted/${versionId}`, '{}');
};

export const storeIndexApi = (versionId) => {
  return handlePostResultAsString(`/index/storeBlockIndex/${versionId}`, '{}');
};

export const createBlockApi = (data) => {
  return handlePostResultAsString(`/block/init`, data);
};

export const storeBlockApi = (versionId, locale, data) => {
  delete  data.availableChildFormats
    console.log('1111', {versionId, locale, data})
  return handlePostToApi(`/block/store/${versionId}/${locale}`, data);
};

export const updateBlockApi = (versionId, locale, data) => {
  return handlePatchToApi(`/block/update/${versionId}/${locale}`, data);
};

export const updateTableApi = (versionId, locale, data) => {
  return handlePatchToApi(`/table/update/${versionId}/${locale}`, data);
};

// table end

export const createPhraseApi = (data) => {
  return handlePostResultAsString(`/phrase/init`, data);
};

export const createPhraseWithTextApi = (data) => {
  return handlePostResultAsString(`/phrase/initWithText`, data);
};

export const initImgApi = (data) => {
  return handlePostResultAsString(`/image/init`, data);
};

export const createImgApi = (parentVersionId, locale, data) => {
  return handlePost(`/image/createImage/${parentVersionId}/${locale}`, data);
};

// createImgApi
export const getImage = async (versionId) => {
  const token = localStorage.getItem('accessToken');
  try {
    return await axios.get(`${API_ROOT_CALLS}/image/getImagePreviewById/${versionId}?jwtToken=${token}`, {
      responseType: 'arraybuffer'
    });
  } catch (e) {
    return console.log('getImage Error !', e.messages);
  }
};

export const createTableApi = (data) => {
  return handlePostResultAsString(`/table/init`, data);
};

export const tableStore = (versionId, locale, data) => {
  return handlePost(`/table/store/${versionId}/${locale}`, data);
};

export const createIndexApi = (data) => {
  return handlePostResultAsString(`/index/init`, data);
};

export const createTermDbApi = (data) => {
  return handlePostToApi(`/termdb/initTermDb`, data)
}

export const createPartApi = (data, id) => {
  // return handlePostToApi(`/part/init/${id}`, '{}')
  // return handlePostToApi(`/part/init/${id}`, data)
  return handlePostTextAsParam(`/part/init/${id}`, '{}')
}

export const createSoftwareTextsApi = (data) => {
  return handlePostResultAsString(`/softwareText/init`, data)
}

export const storeSoftwareTextsApi = (data, versionId) => {
  return handlePostToApi(`/softwareText/store/${versionId}`, data)
}

export const storeTermApi = (data, versionId) => {
  return handlePostToApi(`/termdb/storeTerm/${versionId}`, data)
}


export const storePartApi = (data, versionId) => {
  return handlePostToApi(`/part/store/${versionId}`, data)
//   /zert/part/store/{versionId}
}
export const approveVersionApi = (companyId, versionId) => {
  return handlePostTextAsParamResultAsText(`/common/approve/${companyId}/${versionId}`, '{}');
};

export function createVersion(companyId, versionId) {
  return handlePostTextAsParam(`/common/createVersion/${companyId}/${versionId}`, '{}');
}

export const copyElementBlockApi = (data) => {
  return handlePostResultAsString(`/common/copy`, data);
};

export const pasteElementBlockApi = () => {
  return handleQuery(`/common/paste`);
};

export const duplicateElementBlockApi = (versionId,parameter, data) => {
  return handlePostToApi(`/common/duplicate/${versionId}/${parameter}`, data)
};

export const saveCanvasItemPhrase = (versionId, locale, data) => {
  return handlePostResultAsString(`/image/store/${versionId}/${locale}`, data);
};

export const storeImagePhraseFormatted = (versionId, data) => {
  return handlePostResultAsString(`/phrase/storeImagePhraseFormatted/${versionId}`, data);
};

export const  getVersion = (id) => {
  return handleQuery(`/common/versionInfo/${id}`)
}
