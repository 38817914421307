import {handleHistoryUndoRedo} from "@zert-packages/actions/commonReducer";
import {storeBlockApi} from "@zert-packages/components/InfoElement/components/API";
import {loadHistoryUndo, saveHistory} from "@zert-packages/utils/useHistory";

export const historySnippetSelected = async (historyUndoRedo, setValues, dispatch) => {
    const item = historyUndoRedo.action
    setValues((pre) => ({
        ...pre,
        parentItem: item,
        blockClickCreateItemsBlock: false,
        addPublishingFilters: null,
        beforeTreeItem: [],
        action: {
            saveElement: item,
            parentItemCreate: {...item, type: 'save'}
        },
    }));
    dispatch(handleHistoryUndoRedo({action: null}))
    const uuid = historyUndoRedo?.session?.uuid

    const a = await loadHistoryUndo(uuid, item.elementInfo.versionUUID)
     const b =  await saveHistory(uuid)
    console.log('a', {a, b})
    // await storeBlockApi(item.elementInfo.versionId, item?.targetLocale?.language, item)
}