import React from 'react';
import { FormattedMessage } from 'react-intl';
import CheckIcon from '@mui/icons-material/Check';
import { getPluginByMimeTypeIcon } from '@zert-packages/utils/getPluginByMimeType';
import BlockIcon from '@zert-packages/muiadopticons/BlockIcon';
import TranslateIcon from '@mui/icons-material/Translate';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LabelIcon from '@mui/icons-material/Label';
import LockIcon from '@mui/icons-material/Lock';
import FolderOffIcon from '@mui/icons-material/FolderOff';
import NoEncryptionGmailerrorredIcon from '@mui/icons-material/NoEncryptionGmailerrorred';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import TimelineIcon from '@mui/icons-material/Timeline';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import CancelIcon from '@mui/icons-material/Cancel';

export const getButtons = [
  {
    name: <FormattedMessage id="ElementTile.Actions.Save" defaultMessage="Save" />,
    color: '#78ae49',
    type: 'Save',
    icon: <SaveIcon />,
    notApprovedAt: true
  },
  {
    name: <FormattedMessage id="ElementTile.Actions.Cancel" defaultMessage="Cancel" />,
    color: '#78ae49',
    type: 'Cancel',
    icon: <CancelIcon />,
    notApprovedAt: true
  },
  {
    name: <FormattedMessage id="ElementTile.Actions.Approve" defaultMessage="Approve" />,
    icon: <CheckIcon />,
    color: '#78ae49',
    type: 'Approve',
    notApprovedAt: true
  },
  {
    name: <FormattedMessage id="ElementTile.Actions.NewVersion" defaultMessage="New version" />,
    icon: <TimelineIcon />,
    color: '#78ae49',
    type: 'New version',
    approvedAt: true
  },
  {
    name: <FormattedMessage id="ElementTile.Actions.Remove" defaultMessage="Remove" />,
    icon: <DeleteIcon />,
    color: '#F76767',
    type: 'Remove',
    approvedAt: true,
    notApprovedAt: true,
    multiple: true,
    sortMultiple: 8
  },
  {
    name: <FormattedMessage id="ElementTile.Actions.Cut" defaultMessage="Cut" />,
    icon: <ContentCutIcon />,
    color: '#224953',
    type: 'Cut',
    approvedAt: true,
    notApprovedAt: true,
    multiple: true,
    sortMultiple: 7
  },
  {
    name: <FormattedMessage id="ElementTile.Actions.Copy" defaultMessage="Copy" />,
    icon: <ContentCopyIcon />,
    color: '#3B3F40',
    type: 'Copy',
    notApprovedAt: true,
    approvedAt: true,
    obsolete: true,
    multiple: true,
    sortMultiple: 6
  },
  {
    name: <FormattedMessage id="ElementTile.Actions.Paste" defaultMessage="Paste" />,
    color: '#3B3F40',
    icon: <ContentPasteIcon />,
    type: 'Paste',
    approvedAt: true,
    notApprovedAt: true
  },
  {
    name: <FormattedMessage id="ElementTile.Actions.Duplicate" defaultMessage="Duplicate" />,
    icon: <FileCopyIcon />,
    color: '#78ae49',
    type: 'Duplicate',
    approvedAt: true,
    notApprovedAt: true
  },

  {
    name: <FormattedMessage id="ElementTile.Actions.Preview" defaultMessage="Preview" />,
    color: '#224953',
    icon: <VisibilityIcon />,
    type: 'Preview',
    approvedAt: true,
    notApprovedAt: true,
    obsolete: true
  },

  {
    name: <FormattedMessage id="ElementTile.Actions.AddLabel" defaultMessage="Add label" />,
    color: '#224953',
    type: 'Add label',
    approvedAt: true,
    notApprovedAt: true,
    icon: <LabelIcon />
  },
  {
    name: <FormattedMessage id="ElementTile.Actions.LockReference" defaultMessage="Lock reference" />,
    color: '#224953',
    type: 'Lock reference',
    icon: <LockIcon />,
    approvedAt: true,
    notApprovedAt: true
  },

  // {
  //   name: <FormattedMessage id="ElementTile.Actions.Ungroup" defaultMessage="Ungroup" />,
  //   color: "#224953",
  //   type: "Ungroup",
  //   approvedAt: true,
  //   notApprovedAt: true
  // },

  {
    name: <FormattedMessage id="ElementTile.Actions.ApproveSelected" defaultMessage="Approve Selected" />,
    icon: <CheckIcon />,
    color: '#3B3F40',
    type: 'Approve',
    multiple: true,
    sortMultiple: 0
  },
  {
    name: <FormattedMessage id="ElementTile.Actions.CreateGroup" defaultMessage="Create group" />,
    icon: <BlockIcon svg={getPluginByMimeTypeIcon('application/zert-block', true)} />,
    color: '#3B3F40',
    type: 'Create',
    multiple: true,
    sortMultiple: 1
  },

  {
    name: <FormattedMessage id="ElementTile.Actions.SetTranslatable" defaultMessage="Set Translatable" />,
    icon: <TranslateIcon />,
    color: '#3B3F40',
    type: 'SetTranslatable',
    multiple: true,
    sortMultiple: 2
  },

  {
    name: <FormattedMessage id="ElementTile.Actions.SetUntranslatable" defaultMessage="Set Untranslatable" />,
    icon: <TranslateIcon />,
    color: '#3B3F40',
    type: 'SetUntranslatable',
    multiple: true,
    sortMultiple: 3
  },
  {
    name: <FormattedMessage id="ElementTile.Actions.ParagraphFormat" defaultMessage="Paragraph format" />,
    icon: <BlockIcon svg={getPluginByMimeTypeIcon('application/zert-block', true)} />,
    color: '#3B3F40',
    type: 'ParagraphFormat',
    multiple: true,
    sortMultiple: 4
  },
  {
    name: <FormattedMessage id="ElementTile.Actions.Ungroup" defaultMessage="Ungroup" />,
    icon: <FolderOffIcon />,
    color: '#3B3F40',
    type: 'Ungroup'
  },
  {
    name: <FormattedMessage id="ElementTile.Actions.UnlockReference" defaultMessage="Unlock reference" />,
    icon: <NoEncryptionGmailerrorredIcon />,
    color: '#3B3F40',
    type: 'UnlockReference'
  }
];

export const getSideBarMenu = (el) => {
  if (!el) {
    return;
  }
  if (el.obsolete) {
    return getButtons.filter((el) => el.obsolete);
  }

  const res = [
    el?.elementInfo?.approvedAt ? getButtons.filter((el) => el.approvedAt) : null,
    el.elementInfo && !el.elementInfo.approvedAt ? getButtons.filter((el) => el.notApprovedAt) : null
  ]
    .flat(1)
    .filter((el) => el);

  if (el?.elementInfo?.mimeType?.split('/').pop() === 'zert-block') {
    res.push(getButtons.find((el) => el.type === 'Ungroup'));
  }

  if (typeof el?.itemComment === 'string') {
    res.push(getButtons.find((el) => el.type === 'UnlockReference'));
  }

  return res;
};

export const getMultipleItems = () => {
  return getButtons.filter((el) => el.multiple).sort((a, b) => a.sortMultiple - b.sortMultiple);
};
