export const findSelectedBlock = (context, values, id) => {
  if(context){
    return context
  }
  // if(values?.treeItem?.elementInfo && el?.elementInfo){
  //   return el.elementInfo.versionId === values?.treeItem?.elementInfo.versionId
  // }
  if( values?.treeItem){
    return values?.treeItem?.elementInfo?.versionId === id
  }
  return  false
}