export const findActiveLocales = (versionInfo, activeLocales) => {
  if(!versionInfo.info.translatable){
    return activeLocales
  }
  const res = activeLocales.filter(el => versionInfo.versions.find(it => it.locale === el.value))
  if(res.length > 0){
    return  res
  }
  return activeLocales
}

export const adobeTabs = (width, sideBarOpen) => {
  if(width < 310){
    return  -5
  }

  if(width < 400){
    return  -7
  }

  if(width < 450){
    return  -6
  }

  if(width < 510){
    return  -4
  }

  if(width < 600){
    return  -3
  }

  if(width < 650){
    return  -1
  }

  if(!sideBarOpen){
    if(width < 1000){
      return  -2
    }
  }

  if(width < 1000){
    return  -6
  }

  if(width > 1000){
    return  100
  }
  return  -6
}