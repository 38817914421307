import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import MenuToggleButton from "../shared/MenuToggleButton";
import MainList from "./components/MainList";
import CreateNewList from "./components/createNewList/CreateNewList";
import AdditionalList from "./components/AdditionalList";
import ArrowToggleButton, { ARROW_TOGGLE_VARIANTS } from "../shared/ArrowToggleButton";
import Backdrop from "../shared/Backdrop";
import { BACKGROUND_LINEAR_GRADIENT, CONTAINER, MOBILE_VIEW_WIDTH } from "./SIDE_BAR_CONTS";
import HelpButton from "./components/HelpButton";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import { IconButton } from "@mui/material";
import { setLockSidePanel } from "@zert-packages/actions/coreReducers";
import { setSideBarOpen } from "@zert-packages/actions/commonReducer";

const useStyles = makeStyles({
  rootWrapper: {
    position: 'relative',
    zIndex: '1398',
  },
  root: {
    position: "relative",
    maxWidth: CONTAINER.maxWidth,
    marginRight: 0,
    minWidth: CONTAINER.minWidth,
    height: "100%",
    width: CONTAINER.maxWidth,
    overflowY: "auto",
    overflowX: "hidden",
    zIndex: 1200,
    transition: "all 0.5s ease",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "56px 1fr 0.5fr max-content",
    gridGap: '5px',
    background: BACKGROUND_LINEAR_GRADIENT
  },

  rootDesktopView: {
    width: CONTAINER.minWidth
  },
  rootDesktopViewOpen: {
    width: CONTAINER.maxWidth,
    position: 'relative',
    zIndex: '1398',
  },

  rootMobileView: {
    position: "absolute",
    transform: `translateX(-${CONTAINER.maxWidth}px)`,
    overflowY: "auto",
    marginRight: `-${CONTAINER.maxWidth}px`
  },
  rootMobileViewOpen: {
    transform: `translateX(0px)`,
    marginRight: "0px"
  },
  menuLockButtonContainer: {
    position: "absolute",
    zIndex: '1399',
    top: 15,
    left: CONTAINER.maxWidth - 74
  },
  menuToggleButtonContainer: {
    position: "absolute",
    transition: "transform 0.5s ease",
    transform: `translateX(${0}px)`,
    zIndex: '1399',
    top: 15,
    left: 10,

  },
  menuToggleButtonContainerOpen: {
    transform: `translateX(${CONTAINER.maxWidth - 50}px) rotate(360deg)`
  },

  bottomContainer: {},
  topContainerPadding: {
    padding: "8px 15px"
  },
  topContainer: {
    boxShadow: '0px 6px 3px -1px rgba(0, 0, 0, 0.2)',
    padding: "18px 16px",
    "& img": {
      width: 90,
      paddingBottom: "3px"
    }
  },
  help: {
    color: "#1BA1C5",
    margin: "5px"
  },
});

function SideBar({
                   numberBottomList = 0,
                   numberMainList = 0,
                   mainList = [],
                   createNewList = [],
                   additionalList = [],
                   sidebarShortcuts = [],
                   showingHeadBar,
                   headBar
                 }) {
  const mobileView = useMediaQuery(`(max-width:${MOBILE_VIEW_WIDTH}px)`);
  const myUser = useSelector((state) => state.myuser);
  const [open, setOpen] = useState(true);
  //  setSideBarOpen sideBarOpen
  const dispatch = useDispatch();
  const lockSidePanel = useSelector(state =>
    (state.lockSidePanel == null || state.lockSidePanel));
  const [selectedIndex, setSelectedIndex] = useState(null);
  const cl = useStyles();
  const handleClickOnBackdrop = () => {
    if (open && mobileView) {
      onToggle();
    }
  };

  const setHidePermission = (value) => {
    dispatch(setLockSidePanel(value));
  };

  const handleSelectIndex = (index) => {
    setSelectedIndex(index);
    if (!index) {
      setOpen(!mobileView);
    }
  };

  useEffect(() => {
    if (mobileView) {
      setOpen(!mobileView);
    }
  }, [mobileView]);
  const onToggle = () => {
    setOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (!open) {
      setSelectedIndex(null);
    }
  }, [open]);

  const rootClassName = cn({
    [cl.root]: true,
    [cl.rootDesktopView]: !mobileView,
    [cl.rootDesktopViewOpen]: !mobileView && open,
    [cl.rootMobileView]: mobileView,
    [cl.rootMobileViewOpen]: mobileView && open
  });

  const topContainerClasses = cn({
    [cl.topContainer]: true,
    [cl.topContainerPadding]: open
  });

  const menuToggleButtonClassName = cn({
    [cl.menuToggleButtonContainer]: true,
    [cl.menuToggleButtonContainerOpen]: open
  });

  const handleEnter = () => {
    if (!lockSidePanel) {
      setTimeout(() =>{
        setOpen(true)
      },100)
    }
  };

  const handleLeave = () => {
    if (!lockSidePanel) {
      setTimeout(() =>{
        setOpen(false)
      },100)
    }
  };

  const handleLockClick = () => {
    setHidePermission(!lockSidePanel);
  };



  useEffect(() => {
    dispatch(setSideBarOpen(open))
  }, [open])

  return (
    <div
      className={cl.rootWrapper}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}>
      {(mobileView && (!selectedIndex || !open)) && (
        <MenuToggleButton
          IconButtonProps={{
            className: menuToggleButtonClassName,
            size: "small",
            style: { zIndex: 42 }
          }}
          size="small"
          onClick={onToggle}
          IconProps={{ htmlColor: "white" }}
        />
      )}
      {/*{ <div className={cl.webEditorHeaderBg}></div>}*/}
      {!mobileView && !selectedIndex && (
        <>
          {open &&
            <IconButton
              onClick={handleLockClick}
              style={{ height: "27px", width: "27px" }}
              className={cn(cl.menuLockButtonContainer)}
            >
              {lockSidePanel && <LockIcon sx={{ color: "white" }} />}
              {!lockSidePanel && <LockOpenIcon sx={{ color: "white" }} />}
            </IconButton>}
          <div >
            <ArrowToggleButton
              IconButtonProps={{
                size: "small",
                className: menuToggleButtonClassName
              }}
              size="small"
              iconColor="white"
              toggled={open}
              onToggle={onToggle}
              variant={ARROW_TOGGLE_VARIANTS.leftRight}
            />
          </div>
        </>
      )}

      <div className={rootClassName}>
        <div className={topContainerClasses} >{open &&
          <HelpButton onSelect={handleSelectIndex}  />}</div>

        <MainList onlyIcons={!open} list={mainList} onClick={handleClickOnBackdrop} number={numberMainList}
                  sidebarShortcuts={sidebarShortcuts} />
        {myUser && (
          <AdditionalList
            user={myUser}
            onlyIcons={!open}
            list={additionalList}
            number={numberBottomList}
            onClick={handleClickOnBackdrop}
          />
        )}
      </div>
      {mobileView && open && <Backdrop onClick={handleClickOnBackdrop} />}
    </div>
  );
}

export default SideBar;
