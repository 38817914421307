import React, {useContext, useState} from 'react'
import {Button, Menu} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import BlockIcon from "@zert-packages/muiadopticons/BlockIcon";
import {getPluginByMimeTypeIcon} from "@zert-packages/utils/getPluginByMimeType";
import makeStyles from "@mui/styles/makeStyles";
import HistoryIcon from '@mui/icons-material/History';
import {FormattedMessage} from "react-intl";
import {PanelContext} from "./InfoElementWrapper";
import {getButtons} from "../helpers/getSideBarMenu";
import {handleHistoryUndoRedo} from "@zert-packages/actions/commonReducer";
import {saveHistory} from "@zert-packages/utils/useHistory";


const useStyles = makeStyles((theme) => ({
    label: {
        display: "flex",
        gap: 3,
        alignItems: "center",
        // border: '1px solid red',
        cursor: "pointer",
        fontSize: "1.15rem",
        "@media  (max-width: 1600px)": {
            fontSize: "1.05rem"
        }
    },
    menuRoot: {
        '& .MuiList-root': {
            minWidth: 200,
            padding: 5,
            minHeight: 114,
        },
    },
    title: {
        borderBottom: '1px solid rgba(145, 145, 145, 0.25)',
        display: 'flex',
        gap: 5,
        marginBottom: 5,
    },
    list: {
        display: 'flex',
        gap: 5,
        padding: '2px 4px',
        cursor: 'pointer',
    },
    activeList: {
        cursor: 'default',
        background: '#4db1d3',
        color: '#ffffff',
    },
    button: {
        color: "#ffffff",
        background: "#4db1d3",
        minWidth: 80,
        border: "1px solid #4db1d3",
        outline: "none !important",
        cursor: "pointer",
        marginTop: 10,
        "&:hover": {
            background: "none",
            color: "#212529",
            border: "1px solid #4db1d3"
        },
        "&:focus": {
            backgroundColor: "#4db1d3"
        }
    }
}))


const HistoryStepper = () => {
    const cl = useStyles()
    const {block, historyUndoRedo} = useSelector((state) => state);
    const selectedItem = block?.selectedItem
    const [historyStepper, setHistoryStepper] = useState({
        open: false,
        selected: -1,
    })
    const open = historyStepper.open
    const dispatch = useDispatch();

    // console.log('historyUndoRedo', historyUndoRedo)


    const openHistory = (e) => {
        setHistoryStepper(pre => ({...pre, open: e.currentTarget}))
    }

    const handleClose = () => {
        setHistoryStepper(pre => ({...pre, open: false}))
    }

    const handleSelectedItem = (i) => {
        setHistoryStepper(pre => ({...pre, selected: i}))
    }
    // console.log('historyUndoRedo', historyUndoRedo)

    const handleSave = async () => {
        handleClose()

        if (historyStepper.selected < 0) {
            return
        }

        const res = {
            action: 'select',
            ...JSON.parse(historyUndoRedo.undoList[historyStepper.selected].objectSnippet)
        }

        dispatch(handleHistoryUndoRedo({action: res}))
        // await saveHistory(historyUndoRedo)
    }

    const findIcon = (el) => {
        const res = [...getButtons].find(it => it.type === el.name)
        if (!res) {
            return ''
        }
        return res.icon
    }
    // handleHistoryUndoRedo

    return (
        <div>
            <div onClick={(e) => openHistory(e)} className={cl.label}>
                <HistoryIcon/>
                <span>
                    <FormattedMessage id="ElementTile.Actions.History" defaultMessage="history"/>
                </span>
            </div>
            <Menu
                anchorEl={open}
                open={Boolean(open)}
                onClose={handleClose}
                className={cl.menuRoot}
            >
                <div>
                    <div className={cl.title}>
                        <BlockIcon svg={getPluginByMimeTypeIcon(selectedItem?.elementInfo?.mimeType, true)}/>
                        {selectedItem?.elementInfo?.name}
                    </div>
                    {historyUndoRedo?.undoList?.length > 0 ? <div>
                        {historyUndoRedo.undoList.map((el, i) => <div
                            key={i}
                            className={`${cl.list} ${historyStepper.selected === i ? cl.activeList : ''}`}
                            onClick={() => handleSelectedItem(i)}
                        >
                            <div>{findIcon(el)}</div>
                            <div>{el.creationTime}</div>
                            <div>{el.name}</div>
                            <div>{el.executor}</div>
                        </div>)}
                        <Button className={cl.button} onClick={handleSave}>
                            save
                        </Button>
                    </div> : <div>
                        has no history
                    </div>}

                </div>
            </Menu>
        </div>

    )
}

export default HistoryStepper
