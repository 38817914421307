import hasPermit from "@zert-packages/utils/hasPermit";
import { containsMyMeasure } from "@zert-packages/utils/containsMyMeasure";
import {ButtonList} from "../../InfoElement/InfoElementWrapper/ButtonList";
import getCLMPlugins from "clm/src/plugins/Environment";

export const findVersionTranslatable = (versionInfo, lng) => {
  if(!versionInfo.info.translatable){
    return
  }
  const res =  versionInfo.versions.find(it => it.locale === lng.value)
  if(res){
    window.location.href = `/block/${res.versionId}`
  }
  console.log('activeLocalesItem', res.versionId, lng);
}

export const getColor  = (plugin, measure_riskrow, myuser, versionInfo, permits) => {

  if (!plugin.mimeType == "risk-row" && !plugin.mimeType == "measure") {
    if (!hasPermit(plugin, permits)) {
      return {
        background: "linear-gradient(to bottom, #a2a2a2 0%, #888888 100%)",
        backgroundColor: "#a2a2a2"
      };
    }
  } else if (measure_riskrow && !containsMyMeasure(myuser, measure_riskrow)) {
    return {
      background: "linear-gradient(to bottom, #a2a2a2 0%, #888888 100%)",
      backgroundColor: "#a2a2a2"
    };
  }
  if (versionInfo && versionInfo.info.approvedAt) {
    return {
      background: "linear-gradient(to bottom, #80b15b 0%, #78AE4F 100%)",
      backgroundColor: "#78AE4F"
    };
  }
  if (myuser && versionInfo && versionInfo.checkedOutBy && myuser.userName !== versionInfo.checkedOutBy) {
    return{
      background: "linear-gradient(to bottom, #fb4444 0%, #e83535 100%)", // fb4444 - e83535 = 130F0F
      backgroundColor: "#E83535"
    };
  }
  if (versionInfo && versionInfo.info.archivedAt) {
    return {
      background: "linear-gradient(to bottom, #C3BF1B 0%, #b0b00c 100%)",
      backgroundColor: "#b0b00c"
    };
  }
}


function getActivePlugin(url, plugins) {
  return plugins.find(plugin => {
    const regex = new RegExp(plugin.openpath.replace(/:\w+/g, '\\w+'));
    return regex.test(url);
  });
}


export const getTabsHeader = (dispatch, url) => {
  const plugins = getCLMPlugins().filter((el) => el.editor)
  const getPlugin = getActivePlugin(url, plugins)



  if(getPlugin?.mimeType === "table"){
    return  ButtonList(dispatch).filter(el => el.pluginTypes?.includes('table'))
  }

   return  ButtonList(dispatch).filter(el => el.pluginTypes?.includes('all'))
}