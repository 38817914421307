import {handlePost, handlePostToApi, handleQuery} from "@zert-packages/actions/api";
import {handleHistoryUndoRedo} from "@zert-packages/actions/commonReducer";

export const startSessionHistory = (id) => {
    return  handleQuery(`/block/cacheLoad/${id}/`);
}

import axiosInstance from "@zert-packages/utils/axios";
import {API_ROOT_CALLS} from "clm/src/plugins/BlockPlugin/API";






export const loadUndoList =  async (uuid) => {
    try {
        const res =  await axiosInstance.get(API_ROOT_CALLS  +`/block/undoQuery/${uuid}`)
        return res.data
    } catch (e) {
        return []
    }

}

export const loadUndoRedoList =  (uuid) => {
    return  handleQuery(`/block/redoQuery/${uuid}`);
}

export const action = async(uuid, actionName) =>  {
    return  handlePostToApi(`/block/cacheStore/${uuid}/${actionName}`, '{}');
}



export const handleHistory = async ( historyUndoRedo, actionName, dispatch) => {
    const uuid = historyUndoRedo?.session?.uuid
    if(!uuid){
        return
    }
    const saveAction = await  action(uuid, actionName)
    if(!saveAction){
        return
    }

    const undoList = await  loadUndoList(uuid)
    const redoList = await  loadUndoRedoList(uuid)

    dispatch( handleHistoryUndoRedo({session: { ...historyUndoRedo.session, redoable: saveAction.redoable, undoable: saveAction.undoable},
        undoList, redoList}))
}


//info.elementUUID
export const loadHistory =  (uuid) => {
    return  handleQuery(`/block/loadFromCache/${uuid}`);
}


export const  loadHistoryUndo= async (uuid, actionUuid) => {
    return await handlePost(`/block/undo/${uuid}/${actionUuid}`)
}

export const saveHistory = (uuid) => {

    return  handlePostToApi(`/block/storeCached/${uuid}`,'{}');
}

